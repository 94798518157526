<app-table-generator
    *ngIf="tableInput"
    [tableInput]="tableInput"
    [widgetRef]="widgetRef"
    (gridRef)="agGrid = $event"
    [useMaterialIcons]="true"
    [showPagination]="true"
></app-table-generator>

<ng-template #bulkDeleteList>
    <div class="delete-list">
        <li *ngFor="let data of selectedWidgets">
            {{ data.name }}
        </li>
    </div>
</ng-template>
