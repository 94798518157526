import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { BreakpointObserver } from '@angular/cdk/layout';
import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { ApiUrls } from 'src/app/core/classes/ApiUrls';
import { GlobalConfiguration } from 'src/app/core/classes/GlobalConfiguration';
import { Helper } from 'src/app/shared/classes/Helper';
import { HitApi } from 'src/app/shared/classes/HitApi';
import { ModalInjectedData } from 'src/app/shared/classes/ModalInjectedData';
import { Widget } from 'src/app/shared/classes/Widget';
import { Node } from 'src/app/shared/classes/data-structure/tree/Node';
import { Tree } from 'src/app/shared/classes/data-structure/tree/Tree';
import { ConfirmationModalComponent } from 'src/app/shared/components/modal-templates/confirmation-modal/confirmation-modal.component';
import { WysiwygEditorField } from 'src/app/shared/enums/AppearanceType';
import { AssessmentS3DataUrl } from 'src/app/shared/enums/AssessmentS3DataUrl';
import { AuthorizationType } from 'src/app/shared/enums/AuthorizationType';
import { ButtonColorType } from 'src/app/shared/enums/ButtonColorType';
import { ButtonType } from 'src/app/shared/enums/ButtonType';
import { FilterType } from 'src/app/shared/enums/FilterType';
import { FormState } from 'src/app/shared/enums/FormState';
import { IconType } from 'src/app/shared/enums/IconType';
import { ModalType } from 'src/app/shared/enums/ModalType';
import { RequestType } from 'src/app/shared/enums/RequestType';
import { IButtonGeneratorInput } from 'src/app/shared/interfaces/button-generator/IButtonGeneratorInput';
import { IFormGeneratorInput } from 'src/app/shared/interfaces/form-generator/IFormGeneratorInput';
import { IHitApi } from 'src/app/shared/interfaces/hit-api/IHitApi';
import { IIcon } from 'src/app/shared/interfaces/icon-data/IIcon';
import { IConfirmationModal } from 'src/app/shared/interfaces/modal/IConfirmationModal';
import { IModalData } from 'src/app/shared/interfaces/modal/IModalData';
import { IButtonData } from 'src/app/shared/interfaces/table-generator/IButtonData';
import { IAttributeInfo } from 'src/app/shared/interfaces/views/IAttributeInfo';
import { AuditEmailConfigRuleItemType } from 'src/app/shared/interfaces/email-configuration/emailConfigurationTypes';
import { ListHttpService } from 'src/app/shared/services/http/list-http/list-http.service';
import { WidgetHttpService } from 'src/app/shared/services/http/widget-http/widget-http.service';
import { ModalService } from 'src/app/shared/services/modal/modal-service/modal.service';
import { NotificationsService } from 'src/app/shared/services/notifications/notifications.service';
import { WhiteLabelingService } from 'src/app/shared/services/white-labeling/white-labeling.service';
import { EditModuleIconComponent } from '../views/edit-module-icon/edit-module-icon.component';
import { AddWidgetComponent } from './../add-widget/add-widget.component';
import { EmailConfigurationModalComponent } from '../email-configuration-modal/email-configuration-modal.component';
import { EmailConfigModalService } from 'src/app/shared/services/assessment/email-config-service/email-config-modal.service';
@Component({
    selector: 'app-create-assessment',
    templateUrl: './create-assessment.component.html',
    styleUrls: ['./create-assessment.component.sass']
})
export class CreateAssessmentComponent implements OnInit {
    widgetRef: Widget;
    companyLogo: IIcon;
    folderIcon: IIcon = {
        type: IconType.SVG,
        class: 'open_folder',
        extraClass: 'svg-white-fill'
    };
    toggleCloseIcon: IIcon = {
        type: IconType.SVG,
        class: 'collapse_menu',
        extraClass: 'svg-white-fill'
    };
    toggleOpenIcon: IIcon = {
        type: IconType.SVG,
        class: 'menu_icon',
        extraClass: 'svg-white-fill'
    };
    trashIcon: IIcon = {
        type: IconType.SVG,
        class: 'trash',
        extraClass: 'svg-black-fill'
    };
    trashIconWarn: IIcon = {
        type: IconType.SVG,
        class: 'trash',
        extraClass: 'svg-warning-fill'
    };
    moveIcon: IIcon = {
        type: IconType.SVG,
        class: 'move',
        extraClass: 'svg-black-fill'
    };
    menuDots: IIcon = {
        type: IconType.SVG,
        class: 'vertical_menu_dots',
        extraClass: 'svg-black-fill'
    };
    doubleBackArrow: IIcon = {
        type: IconType.FONTAWSOME,
        class: 'fas fa-angle-double-left',
        text: 'Audit',
        extraClass: 'secondaryTextColor'
    };
    backArrow: IIcon = {
        type: IconType.FONTAWSOME,
        class: 'fas fa-angle-left',
        text: 'Section',
        extraClass: 'secondaryTextColor'
    };
    saveDisk: IIcon = {
        type: IconType.SVG,
        class: 'save_disk',
        extraClass: 'svg-blue-info-fill'
    };
    exitArrow: IIcon = {
        type: IconType.SVG,
        class: 'exit_arrow',
        extraClass: 'svg-blue-info-fill'
    };
    spinnerLoader: IIcon = {
        type: IconType.SPINNERLOADER
    };
    loader: boolean = false;
    tree: Tree<IAttributeInfo> = null;
    rootNode: Node<IAttributeInfo> = null;
    menuParentNode: Node<IAttributeInfo> = null;
    assessmentData: Node<IAttributeInfo> = null;
    aclFormGenInput: IFormGeneratorInput = null;
    aclFormGroup: FormGroup = null;
    selectedAcl: string = null;
    Math = Math;
    GlobalConfiguration = GlobalConfiguration;
    showTechnicalSection: boolean = false;
    breakpointForMenuOverlay: Observable<boolean> = this.breakpointObserver
        .observe('(max-width: 1050px)')
        .pipe(
            map((result) => result.matches),
            shareReplay()
        );
    Tab = Tab;
    summaryFormGenInputs: IFormGeneratorInput;
    summaryFormGroup: FormGroup;
    requiredEvidenceFormGenInputs: IFormGeneratorInput;
    requiredEvidenceFormGroup: FormGroup;
    FormState = FormState;
    hideAssessmentModule: boolean = false;
    addSectionButtonGenInput: IButtonGeneratorInput;
    addFrameworkButtonGenInput: IButtonGeneratorInput;
    selectWidgetButtonGenInput: IButtonGeneratorInput;
    saveSummaryButton: IButtonGeneratorInput;
    saveEvidenceButton: IButtonGeneratorInput;
    editIcon: IIcon = {
        type: IconType.SVG_ASSETS,
        class: 'tags',
        extraClass: 'svg-transparent-fill'
    };
    tags: { colorCode: string; name: string }[] = [];
    aclConfirmationModalId: Symbol;
    activeTabIndex = 0;
    globalArray = [];
    statusMap = {};
    emailConfigurationButtonGenInput: IButtonGeneratorInput;
    @ViewChild("emailConfiguration") emailConfiguration;

    constructor(
        public modalInputData: ModalInjectedData,
        public whiteLabelingService: WhiteLabelingService,
        private listHttpService: ListHttpService,
        private breakpointObserver: BreakpointObserver,
        private modalService: ModalService,
        private notificationService: NotificationsService,
        private widgetHttpService: WidgetHttpService,
        private emailConfigService: EmailConfigModalService,
        private cdRef: ChangeDetectorRef
    ) {
        this.widgetRef = this.modalInputData.data.widgetRef;
    }

    ngOnInit(): void {
        this.companyLogo = {
            type: IconType.IMAGE,
            class:
                this.whiteLabelingService.whiteLableData.lightLogo ||
                this.whiteLabelingService.whiteLableData.darkLogo
        };
        if (this.modalInputData.data && this.modalInputData.data.edit) {
            this.modalInputData.data.menuParentNode.children.map((childNode) =>
                this.fetchDataAndUpdateData(childNode)
            );
            this.tree = this.modalInputData.data.tree;
            this.rootNode = this.modalInputData.data.rootNode;
            this.menuParentNode = this.modalInputData.data.menuParentNode;
            this.assessmentData = this.rootNode;
            this.selectedAcl = this.modalInputData.data.selectedAcl;
        } else {
            this.initTree();
        }

        window.addEventListener('click', (event) => {
            document
                .querySelectorAll('.menu-item .actions')
                .forEach((actionContainer) => {
                    if (
                        actionContainer &&
                        !actionContainer.contains(
                            event.target as globalThis.Node
                        )
                    ) {
                        actionContainer.classList.remove('triggered');
                    }
                });
        });
        this.initAclListing();
        this.initTechnicalDescForms();
        this.generateButtons();
    }
    fetchDataAndUpdateData(node) {
        if (!node.data.framework) {
            node.children.map((childNode) => {
                this.fetchDataAndUpdateData(childNode);
            });
        } else {
            this.getSummary(node);
            this.getRequiredEvidence(node);
        }
    }
    getSummary(node) {
        const apiArgs: IHitApi = {
            url: node.data.framework.summary,
            input: {},
            requestType: RequestType.GET,
            uniqueIdentity: Symbol(),
            config: {
                authorization: AuthorizationType.NOT_AUTHORIZED,
                ignoreBaseUrl: true,
                defaultHeaders: {
                    'Content-Type': 'text/plain'
                },
                responseType: 'text'
            },
            function: (res) => {
                node.data.framework.summary = atob(res.split(',')[1]);
            },
            errorFunction: (error) => {
                Helper.showErrorMessage(this.notificationService, error);
                this.cdRef.detectChanges();
            }
        };
        new HitApi(
            apiArgs,
            this.widgetHttpService,
            this.widgetHttpService.ngZone
        ).hitApi();
    }
    getRequiredEvidence(node) {
        const apiArgs: IHitApi = {
            url: node.data.framework.requiredEvidence,
            input: {},
            requestType: RequestType.GET,
            uniqueIdentity: Symbol(),
            config: {
                authorization: AuthorizationType.NOT_AUTHORIZED,
                ignoreBaseUrl: true,
                defaultHeaders: {
                    'Content-Type': 'text/plain'
                },
                responseType: 'text'
            },
            function: (res) => {
                node.data.framework.requiredEvidence = atob(res.split(',')[1]);
            },
            errorFunction: (error) => {
                Helper.showErrorMessage(this.notificationService, error);
                this.cdRef.detectChanges();
            }
        };
        new HitApi(
            apiArgs,
            this.widgetHttpService,
            this.widgetHttpService.ngZone
        ).hitApi();
    }
    generateButtons() {
        this.addSectionButtonGenInput = {
            buttonName: 'Add Section',
            buttonColorType: ButtonColorType.SECONDARY,
            buttonType: ButtonType.FLAT,
            function: () => {
                this.sectionDetailsUpdate(null, 'Enter Section Details');
            },
            customClass: 'add-section'
        };
        this.addFrameworkButtonGenInput = {
            buttonName: 'Add Controls Points',
            buttonColorType: ButtonColorType.PRIMARY,
            buttonType: ButtonType.FLAT,
            function: () => {
                this.addFramework();
            }
        };
        this.selectWidgetButtonGenInput = {
            buttonName: 'Add Widgets',
            buttonColorType: ButtonColorType.PRIMARY,
            buttonType: ButtonType.FLAT,
            function: () => {
                this.addWidgetsToSection();
            }
        };
        this.saveSummaryButton = {
            buttonName: 'Save',
            buttonColorType: ButtonColorType.SECONDARY,
            buttonType: ButtonType.FLAT,
            function: () => {
                const fieldValue =
                    this.summaryFormGroup &&
                    this.summaryFormGroup.get('summary')
                        ? this.summaryFormGroup.get('summary').value
                        : '';
                if (!fieldValue?.length) {
                    this.notificationService.showSnackBar(
                        'Enter data in Control Point.',
                        true
                    );
                    return;
                }
                this.changeSummaryformState(FormState.IDLE);
            }
        };
        this.saveEvidenceButton = {
            buttonName: 'Save',
            buttonColorType: ButtonColorType.SECONDARY,
            buttonType: ButtonType.FLAT,
            function: () => {
                const fieldValue =
                    this.requiredEvidenceFormGroup &&
                    this.requiredEvidenceFormGroup.get('evidence')
                        ? this.requiredEvidenceFormGroup.get('evidence').value
                        : '';
                if (!fieldValue?.length) {
                    this.notificationService.showSnackBar(
                        'Enter data in Control Point.',
                        true
                    );
                    return;
                }
                this.changeReqEvidenceformState(FormState.IDLE);
            }
        };
        this.emailConfigurationButtonGenInput = {
            buttonName: 'Email Configuration',
            matMenu: this.emailConfiguration,
            function: (buttonRef: IButtonGeneratorInput) => {
                this.emailConfigurationModal();
            },
            buttonColorType: ButtonColorType.DEFAULT,
            buttonType: ButtonType.TEXT,
            showLoader: true,
            customClass: 'email-configuration-button'
        }
    }
    emailConfigurationModal(stepData?) {
        const selectedRuleConfigType = this.emailConfigService.selectedRuleConfig;
        const rulesConfig: AuditEmailConfigRuleItemType[] =
            this.emailConfigService.emailConfigRules.get(
                selectedRuleConfigType
            );
        const modalData: IModalData = {
            modalName: 'Email Configuration',
            modalIcon: null,
            sourceId: Symbol(),
            modalType: ModalType.MIDDLE,
            modalHeightVh: 96,
            modalWidthVw: 60,
            noStepPadding: true,
            resetModal: true,
            modalSteps: [
                {
                    stepData: {
                        componentToLoad: EmailConfigurationModalComponent,
                        payload: {
                            data: {
                                widgetRef: this.widgetRef,
                                emailConfigRulesData: rulesConfig && rulesConfig.length > 0
                                    ? rulesConfig
                                    : [],
                                    selectedRuleType: selectedRuleConfigType ? selectedRuleConfigType : 'Default'
                            }
                        }
                    },
                    stepName: 'Step 1'
                }
            ]
        };
        this.modalService.openModal(modalData);
    }
    initAclListing() {
        this.aclFormGenInput = {
            formName: 'ACL Listing',
            state: FormState.CREATE,
            submitButton: null,
            fields: [
                {
                    fieldType: FilterType.DROPDOWN_SINGLE,
                    label: 'Select ACL',
                    name: 'acl',
                    placeholder: 'Select ACL',
                    apiInfo: this.listHttpService.listAclApiInfo,
                    responseValueKey:"content",
                    required: false,
                    showKey: 'name',
                    value: this.selectedAcl ? this.selectedAcl : ''
                }
            ]
        };
    }
    initTechnicalDescForms() {
        this.tags = null;
        this.summaryFormGenInputs = {
            formName: 'Summary',
            state: FormState.EDIT,
            submitButton: null,
            fields: [
                {
                    fieldType: FilterType.WYSIWYG_EDITOR,
                    appearance: WysiwygEditorField.TYPE_3,
                    label: '',
                    name: 'summary',
                    placeholder: 'Summary',
                    required: false,
                    value: '',
                    extraClass: 'description-content',
                    showTooltip: false,
                    disableFontChange: true,
                    characterLimit: 10000,
                    customInputCallback: (
                        totalCharacterCount,
                        leftOverCharacterCount
                    ) => {
                        const button: IButtonGeneratorInput = Helper.cloneDeep(
                            this.saveSummaryButton
                        );
                        if (leftOverCharacterCount >= 0) {
                            button.disable = false;
                        } else {
                            button.disable = true;
                        }
                        this.saveSummaryButton = button;
                    }
                }
            ]
        };
        this.requiredEvidenceFormGenInputs = {
            formName: 'Required Evidence',
            state: FormState.EDIT,
            submitButton: null,
            fields: [
                {
                    fieldType: FilterType.WYSIWYG_EDITOR,
                    appearance: WysiwygEditorField.TYPE_3,
                    label: '',
                    name: 'evidence',
                    placeholder: 'Evidence',
                    required: false,
                    value: '',
                    extraClass: 'description-content',
                    showTooltip: false,
                    characterLimit: 10000,
                    disableFontChange: true,
                    customInputCallback: (
                        totalCharacterCount,
                        leftOverCharacterCount
                    ) => {
                        const button: IButtonGeneratorInput = Helper.cloneDeep(
                            this.saveEvidenceButton
                        );
                        if (leftOverCharacterCount >= 0) {
                            button.disable = false;
                        } else {
                            button.disable = true;
                        }
                        this.saveEvidenceButton = button;
                    }
                }
            ]
        };
    }
    initTree() {
        // Creating Tree
        this.tree = new Tree<IAttributeInfo>();
        // Adding root node
        this.rootNode = this.tree.addNode(
            {
                attributeName: 'Audit Name',
                iconType: IconType.SVG,
                icon: 'connection'
            },
            null
        );
        this.assessmentData = this.rootNode;
        this.menuParentNode = this.rootNode;
    }
    changeSummaryformState(state: FormState) {
        this.summaryFormGenInputs.state = state;
        const fieldValue =
            this.summaryFormGroup && this.summaryFormGroup.get('summary')
                ? Helper.cleanHTML(this.summaryFormGroup.get('summary').value)
                : '';

        if (state === FormState.IDLE && this.summaryFormGroup) {
            this.summaryFormGenInputs.fields[0].value = fieldValue;
        }

        this.summaryFormGenInputs.fields[0].fieldType =
            state === FormState.EDIT
                ? FilterType.WYSIWYG_EDITOR
                : FilterType.CONTENT;

        this.summaryFormGenInputs = Helper.cloneDeep(this.summaryFormGenInputs);
        if (this.summaryFormGenInputs.fields[0].fieldType === FilterType.WYSIWYG_EDITOR) {
            this.summaryFormGenInputs.fields[0].appearance = WysiwygEditorField.TYPE_3;
        }
        if (this.summaryFormGroup) {
            this.summaryFormGroup.get('summary').setValue(fieldValue);
        }
    }
    changeReqEvidenceformState(state: FormState) {
        this.requiredEvidenceFormGenInputs.state = state;
        const fieldValue =
            this.requiredEvidenceFormGroup &&
            this.requiredEvidenceFormGroup.get('evidence')
                ? Helper.cleanHTML(
                      this.requiredEvidenceFormGroup.get('evidence').value
                  )
                : '';
        if (state === FormState.IDLE && this.requiredEvidenceFormGenInputs) {
            this.requiredEvidenceFormGenInputs.fields[0].value = fieldValue;
        }

        this.requiredEvidenceFormGenInputs.fields[0].fieldType =
            state === FormState.EDIT
                ? FilterType.WYSIWYG_EDITOR
                : FilterType.CONTENT;
        this.requiredEvidenceFormGenInputs = Helper.cloneDeep(
            this.requiredEvidenceFormGenInputs
        );
        if (this.requiredEvidenceFormGenInputs.fields[0].fieldType === FilterType.WYSIWYG_EDITOR) {
            this.requiredEvidenceFormGenInputs.fields[0].appearance = WysiwygEditorField.TYPE_3;
        }
        if (this.requiredEvidenceFormGroup) {
            this.requiredEvidenceFormGroup.get('evidence').setValue(fieldValue);
        }
    }
    addSection(data: IAttributeInfo, modalHeading) {
        // Adding new section
        const parentNodeId = this.menuParentNode
            ? this.menuParentNode.nodeId
            : this.rootNode.nodeId;
        if (parentNodeId) {
            if (this.getExistingSectionNames().includes(data.attributeName)) {
                this.widgetRef.notificationsService.showSnackBar(
                    'Section name already exists',
                    true
                );
                return;
            }
            this.tree.addNode(data, parentNodeId);
        } else {
            this.sectionDetailsUpdate(null, modalHeading);
        }
    }
    addWidgetsToSection() {
        if (!this.selectedAcl) {
            this.widgetRef.notificationsService.showSnackBar(
                'Select ACL first',
                true
            );
            Helper.markAllFieldAsTouched(this.aclFormGroup);
            return;
        }
        this.widgetRef.modalService.openModal({
            modalIcon: null,
            modalName: 'Select Widgets',
            modalType: ModalType.MIDDLE,
            sourceId: this.widgetRef.uniqueIdentity,
            noBottomPadding: true,
            noStepPadding: true,
            modalWidthVw: 95,
            modalHeightVh: 97,
            modalSteps: [
                {
                    stepName: 'Step 1',
                    stepData: {
                        componentToLoad: AddWidgetComponent,
                        payload: {
                            data: {
                                tree: this.tree,
                                node: this.menuParentNode,
                                acl: this.selectedAcl,
                                widgetRef: this.widgetRef
                            }
                        }
                    }
                }
            ]
        });
    }
    addFramework() {
        if (
            this.menuParentNode.children.length ||
            this.menuParentNode.nodeId === this.rootNode.nodeId
        ) {
            return;
        }
        if (
            this.menuParentNode.children &&
            !this.menuParentNode.children.length
        ) {
            if (this.summaryFormGroup && this.requiredEvidenceFormGroup) {
                this.menuParentNode.data['framework'] = {
                    summary: this.summaryFormGroup.get('summary').value,
                    requiredEvidence:
                        this.requiredEvidenceFormGroup.get('evidence').value,
                    tags: this.tags
                };
            } else {
                this.menuParentNode.data['framework'] = {
                    summary: '',
                    requiredEvidence: '',
                    tags: null
                };
            }
        }
        this.initTechnicalDescForms();
        this.showTechnicalSection = !this.showTechnicalSection;
    }
    getExistingSectionNames(): string[] {
        return this.menuParentNode &&
            this.menuParentNode.children &&
            this.menuParentNode.children.length
            ? this.menuParentNode.children.map(
                (child) => child.data['attributeName']
            )
            : [];
    }
    sectionDetailsUpdate(node: Node<IAttributeInfo>, modalHeading: string) {
        const level = this.tree.getLevelOfNode(this.menuParentNode);
        if (level > GlobalConfiguration.MAX_LAYERS_LIMIT) {
            this.widgetRef.notificationsService.showSnackBar(
                `You can create maximum of ${GlobalConfiguration.MAX_LAYERS_LIMIT} nested layers of section`
            );
            return;
        }

        let existingModules = this.getExistingSectionNames();

        if (node && node.data && node.data.attributeName) {
            existingModules = Helper.removeStringfromArray(
                node.data.attributeName,
                existingModules
            );
        }

        const performAction = () => {
            this.widgetRef.modalService.openModal({
                noHeader: true,
                modalIcon: null,
                modalName: 'Icon Update',
                modalType: ModalType.MIDDLE,
                sourceId: this.widgetRef.uniqueIdentity,
                modalAutoHeight: true,
                modalWidthVw: 45,
                noStepPadding: true,
                modalSteps: [
                    {
                        stepName: 'Step 1',
                        stepData: {
                            componentToLoad: EditModuleIconComponent,
                            payload: {
                                data: {
                                    tree: this.tree,
                                    node,
                                    addModuleFunction:
                                        this.addSection.bind(this),
                                    updateModuleFunction:
                                        this.updateSection.bind(this),
                                    existingModules,
                                    isAssessee:
                                        node && node.parentNodeId
                                            ? true
                                            : !node
                                                ? true
                                                : false,
                                    heading: modalHeading,
                                    isAssessment: true,
                                    fieldLabel: modalHeading
                                        .toLowerCase()
                                        .includes('assessment')
                                        ? 'Assessment'
                                        : 'Section'
                                }
                            }
                        }
                    }
                ]
            });
        };
        // if node is null, add new node
        if (node === null && this.menuParentNode.data['framework']) {
            // Children exist.. confirm before adding
            const modalId = this.widgetRef.modalService.openConfirmationModal({
                buttonText: 'Confirm',
                buttonColorType: ButtonColorType.WARN,
                confirmationMessage: `Adding new section to ${this.menuParentNode.data.attributeName} will delete all the already added control points.`,
                contextIcon: {
                    type: IconType.FONTAWSOME,
                    class: 'fas fa-trash-alt',
                    extraClass: 'error-message-custom'
                },
                modalIcon: {
                    type: IconType.FONTAWSOME,
                    class: 'fas fa-exclamation-triangle'
                },
                modalName: 'Remove Section',
                function: (buttonRef: IButtonData) => {
                    this.menuParentNode.data['framework'] = null;
                    if (
                        this.menuParentNode.data &&
                        this.menuParentNode.data['widgets']
                    ) {
                        this.menuParentNode.data['widgets'] = null;
                    }
                    this.requiredEvidenceFormGenInputs.fields.forEach(
                        (field) => {
                            field.value = '';
                        }
                    );
                    this.summaryFormGenInputs.fields.forEach((field) => {
                        field.value = '';
                    });
                    this.tags = null;
                    this.summaryFormGroup = null;
                    this.requiredEvidenceFormGroup = null;
                    this.showTechnicalSection = false;
                    this.widgetRef.modalService.closeModal(null, modalId);
                    performAction();
                }
            });
        } else {
            performAction();
        }
    }
    updateSection(node: Node<IAttributeInfo>) {
        this.tree.updateNodeData(node.data, node.nodeId);
    }
    diveInNode(node: Node<IAttributeInfo>) {
        this.activeTabIndex = 0;
        if (!node.parentNodeId) {
            this.hideAssessmentModule = true;
        }
        this.menuParentNode = node;

        if (
            this.menuParentNode.data.framework &&
            this.menuParentNode.data.framework['summary'] &&
            this.summaryFormGenInputs
        ) {
            this.summaryFormGenInputs.fields.forEach((field) => {
                if (field.name === 'summary') {
                    field.value = this.menuParentNode.data.framework['summary'];
                }
            });
        } else {
            this.summaryFormGenInputs.fields.forEach((field) => {
                if (field.name === 'summary') {
                    field.value = '';
                }
            });
        }
        if (
            this.menuParentNode.data.framework &&
            this.menuParentNode.data.framework['requiredEvidence'] &&
            this.requiredEvidenceFormGenInputs
        ) {
            this.requiredEvidenceFormGenInputs.fields.forEach((field) => {
                field.value =
                    this.menuParentNode.data.framework['requiredEvidence'];
            });
        } else {
            this.requiredEvidenceFormGenInputs.fields.forEach((field) => {
                field.value = '';
            });
        }
        if (
            this.menuParentNode.data.framework &&
            this.menuParentNode.data.framework['tags']
        ) {
            this.tags = this.menuParentNode.data.framework['tags'];
        } else {
            this.tags = null;
        }
        this.initAclListing();
    }

    deleteSection(node: Node<IAttributeInfo>) {
        if (!node) {
            return;
        }
        const modalId = this.widgetRef.modalService.openConfirmationModal({
            buttonText: 'Remove',
            buttonColorType: ButtonColorType.WARN,
            confirmationMessage: `Are you sure you want to remove ${node.data.attributeName}`,
            contextIcon: {
                type: IconType.FONTAWSOME,
                class: 'fas fa-trash-alt',
                extraClass: 'error-message-custom'
            },
            modalIcon: {
                type: IconType.FONTAWSOME,
                class: 'fas fa-exclamation-triangle'
            },
            modalName: 'Remove Section',
            function: (buttonRef: IButtonData) => {
                this.tree.deleteNode(node.nodeId);
                this.widgetRef.modalService.closeModal(null, modalId);
            }
        });
    }
    goToParentNode() {
        this.changeSummaryformState(FormState.IDLE);
        this.changeReqEvidenceformState(FormState.IDLE);
        if (
            this.summaryFormGroup &&
            this.requiredEvidenceFormGroup &&
            this.menuParentNode.children &&
            !this.menuParentNode.children.length
        ) {
            this.menuParentNode.data['framework'] = {
                summary: this.summaryFormGroup.get('summary').value,
                requiredEvidence:
                    this.requiredEvidenceFormGroup.get('evidence').value,
                tags: this.tags
            };
            this.tags = null;
            this.summaryFormGroup = null;
            this.requiredEvidenceFormGroup = null;
        }
        if (!this.menuParentNode.parentNodeId) {
            this.hideAssessmentModule = false;
            this.menuParentNode = this.rootNode;
            return;
        }
        this.showTechnicalSection = false;
        const node = this.tree.getNodeById(this.menuParentNode.parentNodeId);
        if (node.parentNodeId === null) {
            this.menuParentNode = this.rootNode;
        } else {
            this.menuParentNode = node;
        }
    }
    goToRootNode() {
        this.changeSummaryformState(FormState.IDLE);
        this.changeReqEvidenceformState(FormState.IDLE);
        if (this.summaryFormGroup && this.requiredEvidenceFormGroup) {
            this.menuParentNode.data['framework'] = {
                summary: this.summaryFormGroup.get('summary').value,
                requiredEvidence:
                    this.requiredEvidenceFormGroup.get('evidence').value,
                tags: this.tags
            };
            this.tags = null;
            this.summaryFormGroup = null;
            this.requiredEvidenceFormGroup = null;
        }
        this.menuParentNode = this.rootNode;
        this.hideAssessmentModule = false;
        this.showTechnicalSection = false;
    }
    returnToConsole() {
        this.emailConfigService.clearAllConfigData();
        this.widgetRef.modalService.closeModal(
            null,
            this.modalInputData.modalId
        );
    }

    sectionDropped(event: CdkDragDrop<Node<IAttributeInfo>[]>) {
        moveItemInArray(
            this.menuParentNode.children,
            event.previousIndex,
            event.currentIndex
        );
    }
    aclUpdate() {
        if (this.aclFormGroup) {
            if (
                this.selectedAcl &&
                this.selectedAcl !== this.aclFormGroup.get('acl').value &&
                !this.aclConfirmationModalId
            ) {
                // ACL already selected
                this.aclConfirmationModalId =
                    this.widgetRef.modalService.openConfirmationModal({
                        buttonText: 'Continue',
                        buttonColorType: ButtonColorType.WARN,
                        confirmationMessage: `Changing ACL will result in removal of previously added widgets in any section`,
                        contextIcon: {
                            type: IconType.FONTAWSOME,
                            class: 'fas fa-info-circle',
                            extraClass: 'error-message-custom'
                        },
                        modalIcon: {
                            type: IconType.FONTAWSOME,
                            class: 'fas fa-exclamation-triangle'
                        },
                        modalName: 'Update ACL',
                        function: (buttonRef: IButtonData) => {
                            this.removeAllLeafWidgets();
                            this.selectedAcl =
                                this.aclFormGroup.get('acl').value;
                            this.aclFormGenInput.fields.filter((data) => {
                                data.value = this.selectedAcl;
                            });
                            this.widgetRef.modalService.closeModal(
                                null,
                                this.aclConfirmationModalId
                            );
                            this.aclConfirmationModalId = null;
                        },
                        cancelFunction: () => {
                            this.aclFormGroup
                                .get('acl')
                                .setValue(this.selectedAcl);
                            this.aclConfirmationModalId = null;
                        }
                    });
                return;
            }
            if (!this.aclConfirmationModalId) {
                this.selectedAcl = this.aclFormGroup.get('acl').value;
            }
        }
    }
    removeAllLeafWidgets() {
        this.tree.traverseTree(
            (
                node: Node<IAttributeInfo>,
                parentNode: Node<IAttributeInfo>,
                stopTraversalFunction
            ) => {
                if (
                    node &&
                    (!node.children || !node.children.length) &&
                    node.data &&
                    node.data.widgets &&
                    node.data.widgets.length
                ) {
                    node.data.widgets = [];
                }
            }
        );
    }
    widgetDropped(event: CdkDragDrop<any[]>) {
        moveItemInArray(
            this.menuParentNode.data.widgets,
            event.previousIndex,
            event.currentIndex
        );
    }
    deleteWidget(widget) {
        this.menuParentNode.data.widgets =
            this.menuParentNode.data.widgets.filter(
                (item) => item.id !== widget.id
            );
    }
    getKeyForSummary(node) {
        const apiArgs: IHitApi = {
            url: ApiUrls.ADD_DATA_TO_S3 + AssessmentS3DataUrl.SUMMARY,
            input: {},
            requestType: RequestType.GET,
            uniqueIdentity: Symbol(),
            config: {
                authorization: AuthorizationType.BEARER_TOKEN
            },
            function: (res) => {
                this.dumpSummaryToS3(node, res);
            },
            errorFunction: (error) => {
                Helper.showErrorMessage(this.notificationService, error);
            }
        };
        apiArgs.intactUrl = apiArgs.url;
        new HitApi(
            apiArgs,
            this.widgetHttpService,
            this.widgetHttpService.ngZone
        ).hitApi();
    }
    getKeyForRequiredEvidence(node) {
        const apiArgs: IHitApi = {
            url: ApiUrls.ADD_DATA_TO_S3 + AssessmentS3DataUrl.REQUIRED_EVIDENCE,
            input: {},
            requestType: RequestType.GET,
            uniqueIdentity: Symbol(),
            config: {
                authorization: AuthorizationType.BEARER_TOKEN
            },
            function: (res) => {
                this.dumpRequiredEvidenceToS3(res, node);
            },
            errorFunction: (error) => {
                Helper.showErrorMessage(this.notificationService, error);
            }
        };
        apiArgs.intactUrl = apiArgs.url;
        new HitApi(
            apiArgs,
            this.widgetHttpService,
            this.widgetHttpService.ngZone
        ).hitApi();
    }
    dumpSummaryToS3(node, res) {
        const fieldValue = node.data.framework.summary;
        const blob = new Blob([fieldValue], {
            type: 'application/json'
        });
        const fileName = 'summary' + '.json';
        const file = new File([blob], fileName, {
            type: 'application/json'
        });
        Helper.convertTextToBase64(file).then((data) => {
            const { preSignedUrl, key } = res;
            const apiArgs: IHitApi = {
                url: preSignedUrl,
                input: data,
                requestType: RequestType.PUT,
                uniqueIdentity: Symbol(),
                config: {
                    authorization: AuthorizationType.NOT_AUTHORIZED
                },
                function: (res) => {
                    this.statusMap[node.data.attributeName]['SUMMARY_KEY'] =
                        node.data.framework.summary ? key : null;
                    this.getKeyForRequiredEvidence(node);
                },
                errorFunction: (error) => {
                    Helper.showErrorMessage(this.notificationService, error);
                }
            };
            new HitApi(
                apiArgs,
                this.widgetHttpService,
                this.widgetHttpService.ngZone
            ).hitApi();
        });
    }
    dumpRequiredEvidenceToS3(res, node) {
        const fieldValue = node.data.framework.requiredEvidence;
        const blob = new Blob([fieldValue], { type: 'application/json' });
        const fileName = 'evidence' + '.json';
        const file = new File([blob], fileName, { type: 'application/json' });
        Helper.convertTextToBase64(file)
            .then((data) => {
                const { preSignedUrl, key } = res;
                const apiArgs: IHitApi = {
                    url: preSignedUrl,
                    input: data,
                    requestType: RequestType.PUT,
                    uniqueIdentity: Symbol(),
                    config: {
                        authorization: AuthorizationType.NOT_AUTHORIZED
                    },
                    function: (res) => {
                        this.statusMap[node.data.attributeName]['RE_KEY'] =
                            node.data.framework.requiredEvidence ? key : null;
                        this.statusMap[node.data.attributeName]['STATUS'] =
                            true;
                        this.checkIfAllDataCollected();
                    },
                    errorFunction: (error) => {
                        Helper.showErrorMessage(
                            this.notificationService,
                            error
                        );
                    }
                };
                new HitApi(
                    apiArgs,
                    this.widgetHttpService,
                    this.widgetHttpService.ngZone
                ).hitApi();
            })
            .catch((error) => {
                Helper.showErrorMessage(this.notificationService, error);
            });
    }
    saveAudit() {
        if (
            this.tree.getLevelOfNode(this.menuParentNode) === 1 &&
            !this.menuParentNode.children.length
        ) {
            this.widgetRef.notificationsService.showSnackBar(
                'Please add nodes.',
                true
            );
            return;
        }
        if (this.summaryFormGroup && this.requiredEvidenceFormGroup) {
            if (
                !this.summaryFormGroup.get('summary').value ||
                !this.requiredEvidenceFormGroup.get('evidence').value
            ) {
                this.widgetRef.notificationsService.showSnackBar(
                    'Enter data in Control Point.',
                    true
                );
                return;
            }
            this.menuParentNode.data['framework'] = {
                summary: this.summaryFormGroup.get('summary').value,
                requiredEvidence:
                    this.requiredEvidenceFormGroup.get('evidence').value,
                tags: this.tags
            };
        }
        if (this.leafNodesWithoutWidgets().length) {
            this.widgetRef.notificationsService.showSnackBar(
                'Leaf nodes must have control point.',
                true
            );
            return;
        }
        this.loader = true;
        this.sectionToAttribute(this.rootNode);
    }
    leafNodesWithoutWidgets(): Node<IAttributeInfo>[] {
        const leafNodesWithoutWidgets: Node<IAttributeInfo>[] = [];
        this.tree.traverseTree(
            (
                node: Node<IAttributeInfo>,
                parentNode: Node<IAttributeInfo>,
                stopCallback
            ) => {
                if (
                    node &&
                    parentNode &&
                    !node.children.length &&
                    !node.data.framework
                ) {
                    leafNodesWithoutWidgets.push(node);
                }
            }
        );

        return leafNodesWithoutWidgets;
    }
    sectionToAttribute(node: Node<IAttributeInfo>) {
        if (node.children.length) {
            node.children.forEach((child) => {
                this.sectionToAttribute(child);
            });
        } else {
            const widgetIds = [];
            if (node.data.widgets && node.data.widgets.length) {
                node.data.widgets.forEach((widget) => {
                    widgetIds.push(widget.id);
                });
            }
            this.statusMap[node.data.attributeName] = {
                SUMMARY_KEY: '',
                RE_KEY: '',
                STATUS: false,
                attributeName: node.data.attributeName,
                iconType: node.data.iconType,
                icon: node.data.icon,
                widgetIds: widgetIds,
                tags:
                    node.data.framework && node.data.framework['tags']
                        ? node.data.framework['tags']
                        : this.tags
            };
            if (node.data.framework && node.data.framework.summary) {
                this.getKeyForSummary(node);
            } else if (node.data.framework && node.data.framework.requiredEvidence) {
                this.getKeyForRequiredEvidence(node);
            }
        }
    }
    isString(data) {
        return typeof data === 'string' ? true : false;
    }
    checkIfAllDataCollected() {
        const dataCollected = Object.values(this.statusMap).filter(
            (res) => res['STATUS'] === false
        );
        if (!dataCollected.length) {
            const selectedRuleConfigType = this.emailConfigService.selectedRuleConfig;
            const rulesConfig: AuditEmailConfigRuleItemType[] = this.emailConfigService.emailConfigRules.get(selectedRuleConfigType);
            const reqInput = {
                auditName: this.assessmentData.data.attributeName,
                auditIcon: this.assessmentData.data.icon,
                auditIconType: this.assessmentData.data.iconType,
                acl: this.selectedAcl
                    ? this.selectedAcl
                    : this.aclFormGroup?.get('acl')?.value,
                attributes: this.rootNode.children.map((node) =>
                    this.check(node)
                ),
                emailConfigurationsRequestModels: rulesConfig,
            };
            if (selectedRuleConfigType) {
                reqInput['type'] = selectedRuleConfigType;
            }
            const args = Helper.generateHitApiConfig(
                this.modalInputData.data.edit
                    ? this.widgetRef.widgetData.widgetInfo.update
                    : this.widgetRef.widgetData.widgetInfo.create
            );
            if (this.modalInputData.data.edit) {
                args.intactUrl = args.url;
                args.url = args.url.replace(
                    '{auditId}',
                    this.modalInputData.data.assessmentId
                );
            }
            args.input = reqInput;
            args.function = () => {
                this.loader = false;
                this.selectedAcl = null;
                if (this.modalInputData.data.edit) {
                    this.widgetRef.notificationsService.showSnackBar(
                        `${args.input.auditName} updated successfully.`
                    );
                } else {
                    this.widgetRef.notificationsService.showSnackBar(
                        `${args.input.auditName} created successfully.`
                    );
                }
                this.widgetRef.refreshWidget();
                this.emailConfigService.clearAllConfigData();
                this.modalService.closeModal(null, this.modalInputData.modalId);
            };
            args.errorFunction = (error) => {
                this.aclFormGenInput.fields[0].value = this.selectedAcl;
                if (
                    this.menuParentNode.data.framework &&
                    this.menuParentNode.data.framework['summary'] &&
                    this.summaryFormGenInputs
                ) {
                    this.summaryFormGenInputs.fields.forEach((field) => {
                        if (field.name === 'summary') {
                            field.value =
                                this.menuParentNode.data.framework['summary'];
                        }
                    });
                }
                if (
                    this.menuParentNode.data.framework &&
                    this.menuParentNode.data.framework['requiredEvidence'] &&
                    this.requiredEvidenceFormGenInputs
                ) {
                    this.requiredEvidenceFormGenInputs.fields.forEach(
                        (field) => {
                            field.value =
                                this.menuParentNode.data.framework[
                                    'requiredEvidence'
                                ];
                        }
                    );
                }
                if (
                    this.menuParentNode.data.framework &&
                    this.menuParentNode.data.framework['tags']
                ) {
                    this.tags = this.menuParentNode.data.framework['tags'];
                }
                this.loader = false;
                Helper.showErrorMessage(
                    this.widgetRef.notificationsService,
                    error,
                    `Error ${
                        this.modalInputData.data.edit ? 'updating' : 'creating'
                    } assessment.`
                );
            };

            new HitApi(
                args,
                this.widgetRef.httpService,
                this.widgetRef.ngZone
            ).hitApi();
        }
    }
    deleteTag(tagName: string, index: number) {
        const confirmationModalData: IConfirmationModal = {
            function: (modalId) => {
                this.tags.splice(index, 1);
                this.modalService.closeModal(null, modalId);
            },

            modalName: 'Delete Tag',
            modalIcon: {
                type: IconType.FONTAWSOME,
                class: 'fas fa-trash'
            },
            contextIcon: {
                extraClass: 'color-accent',
                type: IconType.FONTAWSOME,
                class: 'fas fa-exclamation-triangle'
            },
            confirmationMessage: `Are you sure you want to delete ${tagName} ?`,
            buttonText: 'Delete',
            buttonColorType: ButtonColorType.WARN,
            loaderOnExec: true
        };
        const modalData: IModalData = {
            modalName: confirmationModalData.modalName,
            modalIcon: confirmationModalData.modalIcon,
            sourceId: Symbol(),
            modalType: ModalType.MIDDLE,
            modalHeightVh: 30,
            modalWidthVw: 40,
            modalSteps: [
                {
                    stepData: {
                        componentToLoad: ConfirmationModalComponent,
                        payload: {
                            data: {
                                function: confirmationModalData.function,
                                params: confirmationModalData
                            }
                        }
                    },
                    stepName: 'Confirmation'
                }
            ]
        };
        this.modalService.openModal(modalData);
    }
    check(node) {
        if (node.children.length) {
            return {
                attributeName: node.data.attributeName,
                iconType: node.data.iconType,
                icon: node.data.icon,
                attributes: node.children.map((childNode) => {
                    return this.check(childNode);
                })
            };
        } else {
            return {
                attributeName: node.data.attributeName,
                iconType: node.data.iconType,
                icon: node.data.icon,
                framework: {
                    summary:
                        this.statusMap[node.data.attributeName]['SUMMARY_KEY'],
                    requiredEvidence:
                        this.statusMap[node.data.attributeName]['RE_KEY'],
                    widgets:
                        this.statusMap[node.data.attributeName]['widgetIds'],
                    tags: this.statusMap[node.data.attributeName]['tags']
                }
            };
        }
    }
    tabChanged(tab) {
        this.activeTabIndex = tab.index;
    }
}
enum Tab {
    TECHNICAL_DESCRIPTION = 'Description',
    UPLOAD_EVIDENCE = 'Upload Evidence',
    TECHNICAL_ASSESSMENT = 'Questionnaire Form'
}
