import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { GridOptions, RowEvent, ValueGetterParams } from 'ag-grid-community';
import { Helper } from 'src/app/shared/classes/Helper';
import { HitApi } from 'src/app/shared/classes/HitApi';
import { IconName } from 'src/app/shared/classes/Icon';
import { Messages } from 'src/app/shared/classes/Messages';
import { Widget } from 'src/app/shared/classes/Widget';
import { WidgetInjectedData } from 'src/app/shared/classes/WidgetInjectedData';
import { ActionState } from 'src/app/shared/enums/ActionState';
import { ActionVisibility } from 'src/app/shared/enums/ActionVisibility';
import { ButtonColorType } from 'src/app/shared/enums/ButtonColorType';
import { ButtonType } from 'src/app/shared/enums/ButtonType';
import { IconType } from 'src/app/shared/enums/IconType';
import { ModalType } from 'src/app/shared/enums/ModalType';
import { IAction } from 'src/app/shared/interfaces/actions/IAction';
import {
    IButtonGeneratorInput,
    IMultiButtonOption
} from 'src/app/shared/interfaces/button-generator/IButtonGeneratorInput';
import { IHitApi } from 'src/app/shared/interfaces/hit-api/IHitApi';
import { IConfirmationModal } from 'src/app/shared/interfaces/modal/IConfirmationModal';
import { IModalData } from 'src/app/shared/interfaces/modal/IModalData';
import { ITableGeneratorInput } from 'src/app/shared/interfaces/table-generator/ITableGeneratorInput';
import { PlanOnboardingModalComponent } from '../../../modal-templates/account-onboarding-modals/plan-onboarding-modal/plan-onboarding-modal.component';
import { BulkUploadAzurePlanAccountComponent } from '../../../modal-templates/azure-plan-onboarding-modal-steps/bulk-upload-azure-plan-account/bulk-upload-azure-plan-account.component';
import { CommonMoreInfoModalComponent } from './../../../modal-templates/common-more-info-modal/common-more-info-modal.component';
import { MultiButtonGeneratorComponent } from './../../../multi-button-generator/multi-button-generator.component';

@Component({
    selector: 'app-plan-listing',
    templateUrl: './plan-listing.component.html',
    styleUrls: ['./plan-listing.component.sass']
})
export class PlanListingComponent implements OnInit {
    tableGenInput: ITableGeneratorInput = null;
    widgetRef: Widget;
    gridRef: GridOptions;
    selectedRowData: Array<string> = [];
    @ViewChild('rowDataSelected') rowDataSelected: TemplateRef<any>;
    @ViewChild('templateData') templateData: TemplateRef<any>;
    mergeWarningMessage: string;
    masterAccountName: string;

    iconName = IconName;
    constructor(widgetData: WidgetInjectedData) {
        this.widgetRef = widgetData.widgetRef;
    }
    allActions: IAction[] = [];

    ngOnInit(): void {
        this.setUpBasics();
        this.applyTagOperation();
    }
    applyTagOperation() {
        const actions: IAction[] = [];
        actions.push({
            state: ActionState.ENABLED,
            visibility: ActionVisibility.VISIBLE,
            message: '',
            function: () => {
                this.mergeWarningMessage = '';
                this.performActions('delete', 'delete', true);
            },
            icon: {
                type: IconType.SVG,
                class: 'trash',
                text: 'Bulk Delete',
                extraClass: 'inline-fix-box-1'
            }
        });
        actions.push({
            state: ActionState.ENABLED,
            visibility: ActionVisibility.VISIBLE,
            message: '',
            function: () => {
                this.openBulkUploadUpdateModal();
            },
            icon: {
                type: IconType.SVG,
                text: 'Bulk Upload',
                class: 'upload',
                extraClass: 'inline-fix-box-1'
            }
        });
        actions.push({
            state: ActionState.ENABLED,
            visibility: ActionVisibility.VISIBLE,
            message: '',
            function: () => {
                this.mergeWarningMessage = '';
                this.openBulkUploadUpdateModal(true);
            },
            icon: {
                type: IconType.SVG,
                text: 'Bulk Update',
                class: 'upload',
                extraClass: 'inline-fix-box-1'
            }
        });
        actions.push({
            state: ActionState.DISABLED,
            visibility: ActionVisibility.VISIBLE,
            tooltip: {
                [ActionState.DISABLED]:
                    'Select only Tier2 subscription Accounts'
            },
            message: null,
            function: () => {
                this.openMergeModal(null, true);
            },
            icon: {
                type: IconType.SVG,
                text: 'Bulk Merge',
                class: 'merge',
                extraClass: 'inline-fix-box-1'
            }
        });
        actions.push({
            state: ActionState.ENABLED,
            visibility: ActionVisibility.VISIBLE,
            message: '',
            function: () => {
                this.mergeWarningMessage = '';
                this.performActions('suspend', 'suspendUnsuspend', true);
            },
            icon: {
                type: IconType.SVG,
                text: 'Bulk Suspend',
                class: 'suspend',
                extraClass: 'inline-fix-box-1'
            }
        });
        actions.push({
            state: ActionState.ENABLED,
            visibility: ActionVisibility.VISIBLE,
            message: '',
            function: () => {
                this.mergeWarningMessage = '';
                this.performActions('resume', 'suspendUnsuspend', true);
            },
            icon: {
                type: IconType.SVG,
                text: 'Bulk Resume',
                class: 'resume',
                extraClass: 'inline-fix-box-1'
            }
        });
        this.allActions = actions;
        this.widgetRef.operationalActions.value.set('Bulk Action', actions);
        this.widgetRef.showNotificationBadge.next(false);
        this.widgetRef.operationalActions.next(
            this.widgetRef.operationalActions.value
        );
    }
    onSelectionChanged() {
        this.gridRef.api.getSelectedNodes().length
            ? this.widgetRef.showNotificationBadge.next(true)
            : this.widgetRef.showNotificationBadge.next(false);
        const resetBulkActions = (checkAccount) => {
            this.allActions[3].state = checkAccount
                ? ActionState.ENABLED
                : ActionState.DISABLED;

            this.allActions[3].tooltip = checkAccount
                ? null
                : {
                      [ActionState.DISABLED]:
                          'Select only Tier2 subscription Accounts'
                  };
            this.widgetRef.operationalActions.value.set(
                'Bulk Action',
                this.allActions
            );
            this.widgetRef.operationalActions.next(
                this.widgetRef.operationalActions.value
            );
        };

        if (this.gridRef.api.getSelectedNodes().length) {
            let checkAccount: boolean = true;
            this.gridRef.api.getSelectedNodes().every((rowData) => {
                if (
                    !(rowData.data['tierTwoKey'] && rowData.data['mergeable'])
                ) {
                    checkAccount = false;
                    return false;
                }
                return true;
            });
            resetBulkActions(checkAccount);
        } else {
            resetBulkActions(false);
        }
    }
    setUpBasics() {
        this.tableGenInput = {
            buttons: [
                {
                    buttonName: 'Add Account',
                    buttonIcon: {
                        type: IconType.FONTAWSOME,
                        class: 'fas fa-plus'
                    },
                    buttonType: ButtonType.FLAT,
                    buttonColorType: ButtonColorType.PRIMARY,
                    function: (buttonRef: IButtonGeneratorInput) => {
                        this.openOnboardingModal();
                    },
                    showLoader: false
                },
                {
                    buttonName: 'Update Linked Account',
                    function: (buttonRef: IButtonGeneratorInput) => {
                        this.hitUpdateLinkApi(buttonRef);
                        if (!this.selectedRowData.length) {
                            buttonRef.loader = true;
                            const args: IHitApi = Helper.generateHitApiConfig(
                                this.widgetRef.widgetData.widgetInfo[
                                    'updateLinkedAccounts'
                                ]
                            );

                            args.input = {};
                            args.function = (response) => {
                                this.widgetRef.refreshWidget();
                                buttonRef.loader = false;
                                this.widgetRef.notificationsService.showSnackBar(
                                    Messages.ACCOUNT_UPDATE_LIST
                                );
                            };
                            args.errorFunction = (error) => {
                                buttonRef.loader = false;
                                Helper.showErrorMessage(
                                    this.widgetRef.notificationsService,
                                    error,
                                    'Error while updating account details'
                                );
                                this.widgetRef.changeDetectorRef.detectChanges();
                            };
                            new HitApi(
                                args,
                                this.widgetRef.httpService,
                                this.widgetRef.ngZone
                            ).hitApi();
                        }
                    },
                    showLoader: true,
                    buttonType: ButtonType.FLAT,
                    buttonColorType: ButtonColorType.PRIMARY
                }
            ],

            listExtraction: {
                type: 'DIRECT'
            },
            refreshWidgetCallback: () => {
                this.widgetRef.showNotificationBadge.next(false);
            },
            noDataMessage: 'No Accounts Available',
            widgetIconData: {
                type: IconType.FONTAWSOME,
                class: 'fas fa-user-plus'
            },

            columns: [
                {
                    columnName: '',
                    columnKey: '',
                    maxWidth: 50,
                    pinned: 'left',
                    filter: false,
                    cellRenderer: (rowData: RowEvent) => {
                        if (
                            rowData.data['type'] === 'CONSOLIDATED' &&
                            rowData.data['tierTwoKey'] &&
                            rowData.data['mergeable']
                        ) {
                            return `<span> <i class="fas fa-circle yellow-severity-color"></i>
                                        </span>`;
                        } else if (rowData.data.status === 'UNSUSPEND') {
                            return `<span>
                            <i class="fas fa-circle green-severity-color"></i>
                                        </span>`;
                        } else {
                            return `<span>
                            <i class="fas fa-circle red-severity-color"></i>
                                        </span>`;
                        }
                    }
                },
                {
                    columnKey: 'accountNumber',
                    columnName: 'Subscription ID',
                    pinned: 'left'
                },
                {
                    columnKey: 'accountName',
                    columnName: 'Account Name'
                },
                {
                    columnKey: 'azurePlanAccountsAttributes',
                    columnName: 'Application ID',
                    columnValueGetter: (rowData: ValueGetterParams) => {
                        return rowData.data.azurePlanAccountsAttributes.appId;
                    }
                },
                {
                    columnKey: 'partnerId',
                    columnName: 'Partner ID',
                    columnValueGetter: (rowData: ValueGetterParams) => {
                        return rowData.data.azurePlanAccountsAttributes
                            .partnerId;
                    }
                },
                {
                    columnKey: 'azurePlanAccountsAttributes',
                    columnName: 'Tenant ID',
                    columnValueGetter: (rowData: ValueGetterParams) => {
                        return rowData.data.azurePlanAccountsAttributes
                            .microsoftTenantId;
                    }
                },
                {
                    columnKey: 'type',
                    columnName: 'Account Type',
                    columnValueGetter: (rowData: ValueGetterParams) => {
                        if (rowData.data['type'] === 'INDEPENDENT') {
                            return 'Partner Account';
                        } else if (rowData.data['type'] === 'CONSOLIDATED') {
                            if (rowData.data['tierTwoKey']) {
                                return 'Tier 2 Subscription Account';
                            } else {
                                return 'Subscription Account';
                            }
                        } else {
                            return 'Linked Account';
                        }
                    }
                },
                {
                    columnKey: 'status',
                    columnName: 'Account Status',
                    columnValueGetter: (rowData: ValueGetterParams) => {
                        if (rowData.data['status'] === 'UNSUSPEND') {
                            return 'Active';
                        }
                        return 'Inactive';
                    }
                },
                {
                    columnKey: 'currency',
                    columnName: 'Currency'
                },
                {
                    columnKey: 'tokenExpiryDate',
                    columnName: 'Token Expiry Date'
                },
                {
                    columnKey: 'action',
                    columnName: 'Action',
                    pinned: 'right',
                    filter: false,
                    minWidth: 320,
                    buttonGen: true,
                    componentFramework: MultiButtonGeneratorComponent,
                    valueFormatter: (rowData: RowEvent) => {
                        const buttons: IButtonGeneratorInput[] = [
                            {
                                buttonName: 'Edit',
                                buttonColorType: ButtonColorType.INFO,
                                buttonType: ButtonType.TEXT,
                                showLoader: true,

                                function: (
                                    buttonRef: IButtonGeneratorInput
                                ) => {
                                    buttonRef.loader = true;

                                    this.getAccountDetails(
                                        rowData.data[
                                            PlanAttributeKeys.ACCOUNT_NUMBER
                                        ],
                                        buttonRef,
                                        rowData.data['type']
                                    );
                                }
                            },
                            {
                                buttonName: 'Delete',
                                buttonColorType: ButtonColorType.WARN,
                                buttonType: ButtonType.TEXT,
                                function: () => {
                                    this.selectedRowData = [];
                                    this.selectedRowData.push(
                                        rowData.data[
                                            PlanAttributeKeys.ACCOUNT_NAME
                                        ]
                                    );
                                    this.widgetRef.changeDetectorRef.detectChanges();
                                    this.mergeWarningMessage = '';
                                    this.performActions(
                                        'delete',
                                        'delete',
                                        false,
                                        rowData.data[
                                            PlanAttributeKeys.ACCOUNT_NUMBER
                                        ]
                                    );
                                }
                            },
                            {
                                buttonName:
                                    rowData.data.status !== 'SUSPEND'
                                        ? 'Suspend'
                                        : 'Resume',
                                function: () => {
                                    this.selectedRowData = [];

                                    this.selectedRowData.push(
                                        rowData.data[
                                            PlanAttributeKeys.ACCOUNT_NAME
                                        ]
                                    );
                                    this.mergeWarningMessage = '';
                                    this.widgetRef.changeDetectorRef.detectChanges();

                                    this.performActions(
                                        rowData.data.status !== 'SUSPEND'
                                            ? 'suspend'
                                            : 'resume',
                                        'suspendUnsuspend',
                                        false,
                                        rowData.data[
                                            PlanAttributeKeys.ACCOUNT_NUMBER
                                        ]
                                    );
                                },
                                buttonColorType: ButtonColorType.SUCCESS,
                                buttonType: ButtonType.TEXT
                            },
                            {
                                buttonName: 'Merge',
                                buttonColorType: ButtonColorType.PRIMARY,
                                buttonType: ButtonType.TEXT,
                                disable: rowData.data['mergeable']
                                    ? false
                                    : true,
                                customClass: !rowData.data['mergeable']
                                    ? 'blur-button'
                                    : '',

                                function: () => {
                                    this.selectedRowData = [];

                                    this.selectedRowData.push(
                                        rowData.data[
                                            PlanAttributeKeys.ACCOUNT_NAME
                                        ] +
                                            ' ' +
                                            `${
                                                rowData.data[
                                                    PlanAttributeKeys
                                                        .ACCOUNT_NUMBER
                                                ]
                                            }`
                                    );

                                    this.mergeWarningMessage =
                                        ConfirmationMessages.warningMessage;
                                    this.openMergeModal(rowData, false);
                                }
                            },
                            {
                                buttonName: 'More Info',
                                buttonColorType: ButtonColorType.INFO,
                                buttonType: ButtonType.TEXT,
                                function: () => {
                                    this.masterAccountName =
                                        this.findPartnerAccountName(
                                            rowData,
                                            rowData.data[
                                                PlanAttributeKeys
                                                    .PLAN_ACCOUNT_ATTRIBUTE
                                            ][PlanAttributeKeys.PARTNER_ID]
                                        );

                                    this.openMoreInfo(
                                        rowData.data['subscriptionMoreInfo'],
                                        rowData.data['mergeable']
                                    );
                                }
                            }
                        ];
                        const buttonOptions: IMultiButtonOption = {
                            layout: {
                                justifyContent: 'space-evenly'
                            }
                        };
                        rowData['options'] = buttonOptions;
                        rowData['buttonGenInputs'] = buttons;
                        return rowData;
                    }
                }
            ],

            afterResponse: null,
            selection: 'multiple',
            columnResizeCount: 8
        };
    }
    openMergeModal(rowData, bulkSelected: boolean, modalInformation?: Object) {
        if (
            !rowData &&
            !this.gridRef.api.getSelectedNodes().length &&
            !modalInformation
        ) {
            Helper.showErrorMessage(
                this.widgetRef.notificationsService,
                null,
                'Select atleast one entry to perform action'
            );
            return;
        }

        let samePartnerIdNotSelected: boolean;
        let selectedButtonName = '';
        let partnerIdSelected;
        if (bulkSelected) {
            this.selectedRowData = [];
            this.gridRef.api.getSelectedNodes().every((rowData, index) => {
                if (
                    rowData.data['mergeable'] &&
                    rowData.data['updateMergeAccount']
                ) {
                    selectedButtonName = 'Update Linked Account & Merge';
                } else {
                    selectedButtonName = 'Merge';
                }
                if (rowData.data['type'] === 'INDEPENDENT') {
                    samePartnerIdNotSelected = true;
                    return false;
                }

                if (!index) {
                    partnerIdSelected =
                        rowData.data[PlanAttributeKeys.PLAN_ACCOUNT_ATTRIBUTE][
                            PlanAttributeKeys.PARTNER_ID
                        ];
                } else {
                    samePartnerIdNotSelected =
                        partnerIdSelected !==
                        rowData.data[PlanAttributeKeys.PLAN_ACCOUNT_ATTRIBUTE][
                            PlanAttributeKeys.PARTNER_ID
                        ]
                            ? true
                            : false;

                    if (samePartnerIdNotSelected) {
                        return false;
                    }
                }
                this.selectedRowData.push(
                    rowData.data[PlanAttributeKeys.ACCOUNT_NAME] +
                        ' ' +
                        `${rowData.data[PlanAttributeKeys.ACCOUNT_NAME]}`
                );

                return true;
            });
        }

        const modalData: IConfirmationModal = {
            modalName: modalInformation
                ? modalInformation['modalName']
                : samePartnerIdNotSelected
                ? 'Merge Tier 2 Subscription Account'
                : 'Merge',

            buttonText: modalInformation
                ? modalInformation['buttonName']
                : selectedButtonName
                ? selectedButtonName
                : rowData.data['updateMergeAccount']
                ? 'Update Linked Account & Merge'
                : 'Merge',
            bodyTemplate: samePartnerIdNotSelected
                ? null
                : this.rowDataSelected,
            modalHeightVh: 40,
            modalWidthVw: 59,
            modalAutoHeight: samePartnerIdNotSelected,
            hideButtons: samePartnerIdNotSelected,
            fontSize: 1,
            hideCancelButton: true,
            extraClass: 'plan-container',
            loaderOnExec: true,
            buttonColorType: ButtonColorType.PRIMARY,
            function: (
                modalId,
                formGroup: FormGroup,
                buttonRef: IButtonGeneratorInput
            ) => {
                if (modalInformation) {
                    modalInformation['callBackFn'](modalId);
                    return;
                }
                this.hitMultipleApi(
                    modalId,
                    buttonRef,
                    rowData && rowData.data['updateMergeAccount']
                        ? true
                        : false,
                    bulkSelected,
                    rowData
                );
            },
            confirmationMessage: samePartnerIdNotSelected
                ? ConfirmationMessages.sameRowNotSelected
                : ConfirmationMessages.mergeMessage.replace(
                      '{masteraccountname}',
                      modalInformation
                          ? this.masterAccountName
                          : this.findPartnerAccountName(
                                rowData,
                                partnerIdSelected
                            )
                  )
        };
        this.widgetRef.modalService.openConfirmationModal(modalData);
    }
    findPartnerAccountName(rowData, partnerIdSelected) {
        this.masterAccountName = '';
        const tier2PartnerId =
            rowData && !partnerIdSelected
                ? rowData.data[PlanAttributeKeys.PLAN_ACCOUNT_ATTRIBUTE][
                      PlanAttributeKeys.PARTNER_ID
                  ]
                : partnerIdSelected;
        if (tier2PartnerId) {
            this.gridRef.api.forEachNode((partnerDetails) => {
                if (
                    partnerDetails.data[
                        PlanAttributeKeys.ACCOUNT_NUMBER
                    ].toLowerCase() === tier2PartnerId.toLowerCase() &&
                    partnerDetails.data['type'] === 'INDEPENDENT'
                ) {
                    this.masterAccountName =
                        partnerDetails.data[PlanAttributeKeys.ACCOUNT_NAME];
                }
            });
        }
        return this.masterAccountName;
    }
    hitMultipleApi(
        modalId: Symbol,
        buttonRef?: IButtonGeneratorInput,
        hitUpdateLinkApi?: boolean,
        bulkSelected?: boolean,
        rowData?,
        apiName?: string,
        payloadForUpdateApi?: Array<[]>,
        payLoadForMerge?: Array<[]>
    ) {
        const mergeApiInfo = () => {
            const apiArgs: IHitApi = Helper.generateHitApiConfig(
                this.widgetRef.widgetData.widgetInfo[
                    `${bulkSelected ? 'bulkMerge' : 'merge'}`
                ]
            );
            apiArgs.input = payLoadForMerge
                ? payLoadForMerge.length === 1 && !bulkSelected
                    ? payLoadForMerge[0]
                    : payLoadForMerge
                : !bulkSelected
                ? {
                      accountName: rowData.data[PlanAttributeKeys.ACCOUNT_NAME],
                      partnerId:
                          rowData.data[
                              PlanAttributeKeys.PLAN_ACCOUNT_ATTRIBUTE
                          ][PlanAttributeKeys.PARTNER_ID],
                      azurePlanAccountsAttributes: {
                          subscriptionID:
                              rowData.data[PlanAttributeKeys.ACCOUNT_NUMBER]
                      },
                      subscriptionID:
                          rowData.data[PlanAttributeKeys.ACCOUNT_NUMBER],
                      type: rowData.data['type']
                  }
                : this.createPayload(true);

            apiArgs.function = () => {
                this.widgetRef.notificationsService.showSnackBar(
                    `Account merge successfully`
                );
                this.widgetRef.refreshWidget();
                this.widgetRef.modalService.closeModal(null, modalId);
            };
            apiArgs.errorFunction = (error) => {
                Helper.showErrorMessage(
                    this.widgetRef.notificationsService,
                    error,
                    'Error while performing merge action'
                );
            };
            new HitApi(
                apiArgs,
                this.widgetRef.httpService,
                this.widgetRef.ngZone
            ).hitApi();
        };
        if (hitUpdateLinkApi) {
            mergeApiInfo();
            return;
        }

        const updateLinkApiInfo: IHitApi = Helper.generateHitApiConfig(
            this.widgetRef.widgetData.widgetInfo[
                apiName ? 'updatelinkedAccount' : 'updateLinkedAccounts'
            ]
        );
        updateLinkApiInfo.input = payloadForUpdateApi
            ? payloadForUpdateApi
            : this.createBulkMergePayload();

        updateLinkApiInfo.function = (response) => {
            mergeApiInfo();
            buttonRef.loader = false;

            this.widgetRef.notificationsService.showSnackBar(
                Messages.ACCOUNT_UPDATE_LIST
            );

            this.widgetRef.modalService.closeModal(null, modalId);
        };
        updateLinkApiInfo.errorFunction = (error) => {
            buttonRef.loader = false;

            this.widgetRef.changeDetectorRef.detectChanges();
            Helper.showErrorMessage(
                this.widgetRef.notificationsService,
                error,
                'Error while updating account'
            );
        };
        new HitApi(
            updateLinkApiInfo,
            this.widgetRef.httpService,
            this.widgetRef.ngZone
        ).hitApi();
    }
    createBulkMergePayload() {
        const data = [];
        this.gridRef.api.getSelectedNodes().forEach((rowData) => {
            data.push({
                accountName: rowData.data[PlanAttributeKeys.ACCOUNT_NAME],
                partnerId:
                    rowData.data[PlanAttributeKeys.PLAN_ACCOUNT_ATTRIBUTE][
                        PlanAttributeKeys.PARTNER_ID
                    ],
                type: 'CONSOLIDATED',
                subscriptionID: rowData.data[PlanAttributeKeys.ACCOUNT_NUMBER]
            });
        });
        return data;
    }
    hitUpdateLinkApi(buttonRef: IButtonGeneratorInput) {
        const payLoadData = [];
        const payLoadDataForMerge = [];
        this.masterAccountName = '';
        this.selectedRowData = [];

        const rowNodesSelected: number =
            this.gridRef.api.getSelectedNodes().length;

        const traverseAllData = (partnerDetails) => {
            if (rowNodesSelected >= 1) {
                payLoadData.push(partnerDetails.data);
            }
            this.gridRef.api.forEachNode((details) => {
                if (
                    details.data[PlanAttributeKeys.PLAN_ACCOUNT_ATTRIBUTE][
                        PlanAttributeKeys.PARTNER_ID
                    ].toLowerCase() ===
                        partnerDetails.data[
                            PlanAttributeKeys.ACCOUNT_NUMBER
                        ].toLowerCase() &&
                    details.data['type'] === 'CONSOLIDATED' &&
                    details.data['tierTwoKey']
                ) {
                    if (
                        !this.masterAccountName.includes(
                            partnerDetails.data[PlanAttributeKeys.ACCOUNT_NAME]
                        )
                    ) {
                        this.masterAccountName +=
                            partnerDetails.data[PlanAttributeKeys.ACCOUNT_NAME];
                        this.masterAccountName +=
                            rowNodesSelected === 1 ? '' : ', ';
                    }
                    this.selectedRowData.push(
                        details.data[PlanAttributeKeys.ACCOUNT_NAME] +
                            ' ' +
                            `${details.data[PlanAttributeKeys.ACCOUNT_NUMBER]}`
                    );
                    if (rowNodesSelected < 1) {
                        payLoadData.push({
                            type: 'CONSOLIDATED',
                            partnerId:
                                details.data[
                                    PlanAttributeKeys.PLAN_ACCOUNT_ATTRIBUTE
                                ][PlanAttributeKeys.PARTNER_ID],
                            accountName:
                                details.data[PlanAttributeKeys.ACCOUNT_NAME],
                            azurePlanAccountsAttributes: {
                                subscriptionID:
                                    details.data[
                                        PlanAttributeKeys.ACCOUNT_NUMBER
                                    ]
                            }
                        });
                    }
                    payLoadDataForMerge.push({
                        accountName:
                            details.data[PlanAttributeKeys.ACCOUNT_NAME],
                        partnerId:
                            details.data[
                                PlanAttributeKeys.PLAN_ACCOUNT_ATTRIBUTE
                            ][PlanAttributeKeys.PARTNER_ID],
                        azurePlanAccountsAttributes: {
                            subscriptionID:
                                details.data[PlanAttributeKeys.ACCOUNT_NUMBER]
                        },
                        type: 'CONSOLIDATED'
                    });
                    this.mergeWarningMessage =
                        ConfirmationMessages.warningMessage;
                }
            });
        };
        if (rowNodesSelected === 1) {
            traverseAllData(this.gridRef.api.getSelectedNodes()[0]);
        } else if (rowNodesSelected > 1) {
            this.gridRef.api.getSelectedNodes().forEach((partnerData) => {
                traverseAllData(partnerData);
            });
        } else {
            this.gridRef.api.forEachNode((rowData) => {
                if (rowData.data.type === 'INDEPENDENT') {
                    traverseAllData(rowData);
                }
            });
        }
        if (!this.selectedRowData.length) {
            return;
        }

        this.openMergeModal(this.gridRef.api.getSelectedNodes()[0], false, {
            modalName: 'Update Linked Account',
            buttonName: 'Update Linked Account & Merge',
            callBackFn: (modalId: Symbol) => {
                this.hitMultipleApi(
                    modalId,
                    buttonRef,
                    false,
                    true,
                    null,
                    rowNodesSelected ? 'selected-updatelinked' : '',
                    payLoadData,
                    payLoadDataForMerge
                );
            }
        });
    }

    openMoreInfo(moreInfoData: Object, showWarningMessage: boolean) {
        const modalData: IModalData = {
            modalName: '',
            modalType: ModalType.SIDE,
            modalIcon: {
                type: IconType.FONTAWSOME,
                class: ''
            },
            noHeader: true,
            sourceId: Symbol(),
            noStepPadding: true,
            modalSteps: [
                {
                    stepName: '',
                    stepData: {
                        componentToLoad: CommonMoreInfoModalComponent,
                        payload: {
                            data: {
                                stepData: moreInfoData,
                                warningMessageTemplate: showWarningMessage
                                    ? this.templateData
                                    : null
                            }
                        }
                    }
                }
            ]
        };
        this.widgetRef.modalService.openModal(modalData);
    }
    openBulkUploadUpdateModal(bulkUpdate?: boolean) {
        let conditionsChecked: boolean = true;
        const rowsData = [];

        if (bulkUpdate) {
            if (this.gridRef.api.getSelectedNodes().length) {
                const accountType =
                    this.gridRef.api.getSelectedNodes()[0].data.type;
                this.gridRef.api
                    .getSelectedNodes()
                    .forEach((rowData, index) => {
                        if (index && rowData.data.type !== accountType) {
                            Helper.showErrorMessage(
                                this.widgetRef.notificationsService,
                                null,
                                'Please select only one type of account to perform this action'
                            );
                            conditionsChecked = false;
                            return;
                        }
                        rowsData.push(rowData.data);
                        if (rowsData.length) {
                            delete rowsData[index]['moreInfo'];
                        }
                    });
            } else {
                Helper.showErrorMessage(
                    this.widgetRef.notificationsService,
                    null,
                    'Select at least One entry to perform the Action'
                );
                return;
            }
        }

        if (!conditionsChecked) {
            return;
        }

        const modalArgs: IModalData = {
            modalName: `Bulk ${
                bulkUpdate ? 'Update' : 'Upload'
            } Azure Plan Account`,
            sourceId: this.widgetRef.uniqueIdentity,
            modalType: ModalType.MIDDLE,
            modalAutoHeight: true,
            modalIcon: {
                type: IconType.FONTAWSOME,
                class: ''
            },
            modalWidthVw: 80,
            modalSteps: [
                {
                    stepName: 'Step 1',
                    resetModal: true,
                    stepData: {
                        componentToLoad: BulkUploadAzurePlanAccountComponent,
                        payload: {
                            data: {
                                widgetRef: this.widgetRef,
                                bulkUpdate: bulkUpdate,
                                accountType:
                                    bulkUpdate && this.gridRef
                                        ? this.gridRef.api.getSelectedNodes()[0]
                                              .data.type
                                        : null,
                                rowsData
                            }
                        }
                    }
                }
            ]
        };
        this.widgetRef.modalService.openModal(modalArgs);
    }
    performActions(
        actionPerform: string,
        apiName: string,
        bulkAction: boolean,
        selectedId?: string
    ) {
        if (bulkAction) {
            this.selectedRowData = [];
            if (this.gridRef && !this.gridRef.api.getSelectedNodes().length) {
                Helper.showErrorMessage(
                    this.widgetRef.notificationsService,
                    null,
                    'Select atleast One entry to perform the Action'
                );
                return;
            } else {
                this.gridRef.api.getSelectedNodes().forEach((rowValue) => {
                    this.selectedRowData.push(
                        rowValue.data[PlanAttributeKeys.ACCOUNT_NAME]
                    );
                });
            }
        }

        const modalData: IConfirmationModal = {
            modalName: `${
                actionPerform.includes('s')
                    ? actionPerform[0] === 's'
                        ? 'Suspend'
                        : 'Resume'
                    : 'Delete'
            } Azure Plan Account`,
            confirmationMessage: `Are you sure you want to ${actionPerform} the following Account ?`,
            buttonText: 'Confirm',
            loaderOnExec: true,
            extraClass: 'plan-container',
            contextIcon: {
                type: IconType.FONTAWSOME,
                class: ''
            },
            fontSize: 1.12,
            bodyTemplate: this.rowDataSelected,
            buttonColorType: actionPerform.includes('delete')
                ? ButtonColorType.WARN
                : ButtonColorType.PRIMARY,

            function: (modalId: Symbol) => {
                const apiArgs: IHitApi = Helper.generateHitApiConfig(
                    this.widgetRef.widgetData.widgetInfo[`${apiName}`]
                );
                apiArgs.input = bulkAction
                    ? this.createPayload()
                    : [selectedId];
                if (actionPerform.includes('s')) {
                    apiArgs.url =
                        apiArgs.url +
                        `?status=${
                            actionPerform[0] === 'r' ? 'UNSUSPEND' : 'SUSPEND'
                        }`;
                }

                apiArgs.function = () => {
                    this.widgetRef.notificationsService.showSnackBar(
                        `Account ${actionPerform} successfully`
                    );
                    this.widgetRef.refreshWidget();
                    this.widgetRef.modalService.closeModal(null, modalId);
                };
                apiArgs.errorFunction = (error) => {
                    Helper.showErrorMessage(
                        this.widgetRef.notificationsService,
                        error,
                        `Error while ${actionPerform} accounts`
                    );
                    this.widgetRef.modalService.closeModal(null, modalId);
                };
                new HitApi(
                    apiArgs,
                    this.widgetRef.httpService,
                    this.widgetRef.ngZone
                ).hitApi();
            }
        };

        this.widgetRef.modalService.openConfirmationModal(modalData);
    }
    createPayload(payLoadForMerge?: boolean) {
        const payloadData = [];

        this.gridRef.api.getSelectedNodes().forEach((rowData) => {
            if (payLoadForMerge) {
                payloadData.push({
                    accountName: rowData.data[PlanAttributeKeys.ACCOUNT_NAME],
                    partnerId:
                        rowData.data[PlanAttributeKeys.PLAN_ACCOUNT_ATTRIBUTE][
                            PlanAttributeKeys.PARTNER_ID
                        ],
                    azurePlanAccountsAttributes: {
                        subscriptionID:
                            rowData.data[PlanAttributeKeys.ACCOUNT_NUMBER]
                    },
                    type: 'CONSOLIDATED'
                });
            } else {
                payloadData.push(
                    rowData.data[PlanAttributeKeys.ACCOUNT_NUMBER]
                );
            }
        });

        return payloadData;
    }

    openOnboardingModal(formData?: any, edit?: boolean) {
        const modalArgs: IModalData = {
            modalName: 'On Board Azure Plan Account',
            sourceId: this.widgetRef.uniqueIdentity,
            modalType: ModalType.MIDDLE,

            modalIcon: {
                type: IconType.FONTAWSOME,
                class: 'fas fa-chalkboard-teacher'
            },
            extraStepContainerClass: 'remove-form-space',
            modalSteps: [
                {
                    stepName: 'AWS ONBOARDING',
                    resetModal: true,
                    stepData: {
                        componentToLoad: PlanOnboardingModalComponent,
                        payload: {
                            data: {
                                create: this.widgetRef.widgetData.widgetInfo
                                    .create,
                                formData,
                                edit: edit ? true : false,
                                widgetRef: this.widgetRef
                            }
                        }
                    }
                }
            ],
            modalWidthVw: 75,
            modalHeightVh: 90
        };
        this.widgetRef.modalService.openModal(modalArgs);
    }
    getAccountDetails(
        accountId,
        buttonRef?: IButtonGeneratorInput,
        accountType?: string
    ) {
        const apiArgs: IHitApi = Helper.generateHitApiConfig(
            this.widgetRef.widgetData.widgetInfo['additionalApisForWidget'][
                'getAccountDetails'
            ]
        );
        apiArgs.intactUrl = apiArgs.url;
        apiArgs.url = apiArgs.url.replace('{id}', accountId);
        apiArgs.input = { type: accountType };

        apiArgs.function = (response) => {
            response['type'] = accountType;
            buttonRef.loader = false;
            this.widgetRef.changeDetectorRef.detectChanges();
            this.openOnboardingModal(response, true);
        };

        apiArgs.endFunction = () => {
            buttonRef.loader = false;
            this.widgetRef.changeDetectorRef.detectChanges();
        };
        new HitApi(
            apiArgs,
            this.widgetRef.httpService,
            this.widgetRef.ngZone
        ).hitApi();
    }
}
enum ConfirmationMessages {
    sameRowNotSelected = 'Bulk Merge action can only be performed for the tier 2 subscriptions having the same Partner Id.',
    warningMessage = 'Note: Once merge action is performed, Master account will become the data source of above tier 2 subscription(s). The data for these subscriptions will be available only from the onboarding date of their Master Account.',
    mergeMessage = 'Master Account {masteraccountname} for this subscription already exists, Do you want the below subscription to be merged under master account?'
}
enum PlanAttributeKeys {
    PLAN_ACCOUNT_ATTRIBUTE = 'azurePlanAccountsAttributes',
    ACCOUNT_NAME = 'accountName',
    ACCOUNT_NUMBER = 'accountNumber',
    SPOC = 'spoc',
    SUBSCRIPTION_ID = 'subscriptionID',
    PARTNER_ID = 'partnerId'
}
