<app-table-generator
    *ngIf="tableInput"
    [tableInput]="tableInput"
    [widgetRef]="widgetRef"
    (gridRef)="agGrid = $event"
    (selectionChanged)="onSelectionChanged($event)"
    [showPagination]="true"
></app-table-generator>
<ng-template #deleteTemplateRef>
    <div
        class="tw-py-2 tw-px-1"
        *ngIf="deleteViewList && deleteViewList.length"
    >
        <li *ngFor="let deleteView of deleteViewList">
            {{ deleteView[JSON_NAME_KEY] }}
        </li>
    </div>
    <div class="tw-text-negative tw-pt-2 tw-pb-3" *ngIf="!isViewDeletable">
        Please note this action will not delete inherited views and views
        attached to any user
    </div>
</ng-template>
