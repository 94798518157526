import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { ApiUrls } from 'src/app/core/classes/ApiUrls';
import { CustomValidators } from 'src/app/shared/classes/CustomValidators';
import { Helper } from 'src/app/shared/classes/Helper';
import { HitApi } from 'src/app/shared/classes/HitApi';
import { ModalInjectedData } from 'src/app/shared/classes/ModalInjectedData';
import { Widget } from 'src/app/shared/classes/Widget';
import {
    FieldConfig,
    salesforceFormConfiguration
} from 'src/app/shared/components/form-builder/form-json';
import { AuthorizationType } from 'src/app/shared/enums/AuthorizationType';
import { ButtonColorType } from 'src/app/shared/enums/ButtonColorType';
import { ButtonType } from 'src/app/shared/enums/ButtonType';
import { FilterType } from 'src/app/shared/enums/FilterType';
import { FormState } from 'src/app/shared/enums/FormState';
import { IconType } from 'src/app/shared/enums/IconType';
import { ModalType } from 'src/app/shared/enums/ModalType';
import { RequestType } from 'src/app/shared/enums/RequestType';
import { IButtonGeneratorInput } from 'src/app/shared/interfaces/button-generator/IButtonGeneratorInput';
import { IFormGeneratorInput } from 'src/app/shared/interfaces/form-generator/IFormGeneratorInput';
import { IHitApi } from 'src/app/shared/interfaces/hit-api/IHitApi';
import { IIcon } from 'src/app/shared/interfaces/icon-data/IIcon';
import { IModalData } from 'src/app/shared/interfaces/modal/IModalData';
import { ModalService } from 'src/app/shared/services/modal/modal-service/modal.service';
import { MultiStepFormService } from 'src/app/shared/services/modal/multi-step-form/multi-step-form.service';
import { SalesforceIntegrationCaseFormComponent } from '../salesforce-integration-case-form/salesforce-integration-case-form.component';
import { IFormField } from './../../../../../interfaces/form-generator/IFormField';

enum FormFieldData {
    LABEL = 'label'
}

@Component({
    selector: 'app-salesforce-integration-step-two',
    templateUrl: './salesforce-integration-step-two.component.html',
    styleUrls: ['./salesforce-integration-step-two.component.sass']
})
export class SalesforceIntegrationStepTwoComponent implements OnInit {
    readonly formFieldHeading: string = 'Add Fields to form';
    formFieldsLabel: string[];
    filteredFormFieldLabel;
    configureButtonsInput: IButtonGeneratorInput[];
    buttonInputs: IButtonGeneratorInput[];
    selectedValue: [];
    configureFormInput: IFormGeneratorInput;
    configureFormGroupRef: FormGroup;
    widgetRef: Widget;
    stepData: IStepData;
    sideFields: IFormGeneratorInput;
    totalFields: IFormGeneratorInput;
    configureFields: IFormField[];
    isRequired: Map<string, boolean> = new Map();
    isChecked: Map<string, boolean> = new Map();
    configurationForms = salesforceFormConfiguration;
    tempFormGroupMap: Map<symbol, FormGroup> = new Map<symbol, FormGroup>();
    finalFormGroupMap: Map<symbol, FormGroup> = new Map<symbol, FormGroup>();
    currentConfigureFieldName: string;
    editIcon: IIcon = {
        type: IconType.SVG,
        class: 'pencil_icon_v2',
        extraClass: 'svg-info-fill'
    };
    deleteIcon: IIcon = {
        type: IconType.SVG,
        class: 'trash_v2',
        extraClass: 'svg-delete-icon-fill'
    };
    isEdit: boolean = false;
    step2Data: IFormGeneratorInput;
    resetModal: Subscription;
    constructor(
        private modalInjectedData: ModalInjectedData,
        private multiStepFormService: MultiStepFormService,
        private modalService: ModalService
    ) {
        this.widgetRef = modalInjectedData.data.widgetRef;
        this.isEdit = modalInjectedData.data.isEdit;

        if (this.isEdit) {
            this.step2Data = modalInjectedData.data['response']['step2'];
        }
    }

    ngOnInit(): void {
        this.stepData = this.multiStepFormService.stepData
            .get(this.modalInjectedData.modalId)
            .get(2);

        if (
            this.stepData['isRequired'] &&
            this.stepData['configureFields'] &&
            this.stepData['sideFields'] &&
            this.stepData['isChecked']
        ) {
            this.isRequired = this.stepData.isRequired;
            this.configureFields = this.stepData['configureFields'];
            this.sideFields = this.stepData.sideFields;
            this.checkFileType();
            this.isChecked = this.stepData['isChecked'];
        } else {
            this.sideFields = this.stepData['form'];
            this.checkFileType();
            this.getCongiguredFields();
        }
        this.totalFields = Helper.cloneDeep(this.sideFields);
        this.generateConfigureButtons();
        this.setFormFieldsName();
        this.buttonInputs = [
            {
                buttonName: 'Back',
                buttonColorType: ButtonColorType.PRIMARY,
                buttonType: ButtonType.STROKED,
                showLoader: true,
                function: () => {
                    this.multiStepFormService.previousStep(
                        this.modalInjectedData.modalId
                    );
                },
                hostClass: 'tw-mr-auto'
            },
            {
                buttonName: 'Test',
                buttonColorType: ButtonColorType.PRIMARY,
                buttonType: ButtonType.RAISED,
                showLoader: true,
                function: () => {
                    this.openCaseHandlingModal();
                }
            },
            {
                buttonName: 'Next',
                buttonColorType: ButtonColorType.PRIMARY,
                buttonType: ButtonType.RAISED,
                showLoader: true,
                function: (buttonRef) => {
                    this.getUsersData(buttonRef);
                }
            }
        ];
        this.resetModal = this.modalService.resetModal.subscribe((data) => {
            this.isRequired.clear();
            this.isChecked.clear();
            this.tempFormGroupMap.clear();
            this.finalFormGroupMap.clear();
            this.configureFields = [];
            this.getCongiguredFields();
        });
    }
    getUsersData(buttonRef: IButtonGeneratorInput) {
        if (buttonRef.loader) {
            return;
        }
        buttonRef.loader = true;
        const hitApi: IHitApi = {
            url: ApiUrls.USERS_END_POINT_WITH_PAGINATION,
            requestType: RequestType.GET,
            config: {
                authorization: AuthorizationType.BEARER_TOKEN
            },

            function: (response) => {
                buttonRef.loader = false;
                response = this.parseUsersData(response['content']);
                this.goToNextStep(response['content']);
            },
            errorFunction: (error) => {
                Helper.showErrorMessage(
                    this.widgetRef.notificationsService,
                    error
                );
                buttonRef.loader = false;
            },
            input: {},

            uniqueIdentity: Symbol()
        };

        new HitApi(
            hitApi,
            this.widgetRef.httpService,
            this.widgetRef.ngZone
        ).hitApi();
    }
    parseUsersData(usersData) {
        const userStepData = [];
        usersData.forEach((userData) => {
            const data = {
                username: userData.firstName + ' ' + userData.lastName,
                id: userData.id,
                email: userData.email
            };
            userStepData.push(data);
        });
        return userStepData;
    }
    goToNextStep(thirdStepData) {
        if (this.configureFormInput) {
            return;
        }
        const form = {
            formName: 'Salesforce form',
            state: FormState.CREATE,
            submitButton: null,
            fields: this.configureFields
        };
        this.multiStepFormService.stepData
            .get(this.modalInjectedData.modalId)
            .set(2, {
                form: form,
                sideFields: this.sideFields,
                isRequired: this.isRequired,
                isChecked: this.isChecked,
                configureFields: this.configureFields
            });
        if (
            !this.multiStepFormService.stepData
                .get(this.modalInjectedData.modalId)
                .has(3)
        ) {
            this.multiStepFormService.stepData
                .get(this.modalInjectedData.modalId)
                .set(3, { userData: thirdStepData });
        }

        this.multiStepFormService.nextStep(this.modalInjectedData.modalId);
    }

    getCongiguredFields() {
        this.configureFields = this.sideFields.fields.filter((field) => {
            let index = -1;
            if (this.step2Data) {
                index = this.step2Data.fields.findIndex(
                    (formField) => formField.name === field.name
                );
            }
            if (this.isEdit && index !== -1) {
                const configuredField = this.step2Data.fields[index];
                if (field.required) {
                    this.isRequired.set(field.name, true);
                }
                this.isChecked.set(configuredField.name, true);
                field.value = configuredField.value;
                field.required = configuredField.required;
                field.placeholder = configuredField.placeholder
                    ? configuredField.placeholder
                    : configuredField['placeHolder'];
                return field;
            } else if (field.required) {
                this.isChecked.set(field.name, true);
                this.isRequired.set(field.name, true);
                return field;
            }
        });
        this.configureFields = Helper.cloneDeep(this.configureFields);
    }

    generateConfigurationForm(field: IFormField) {
        this.currentConfigureFieldName = field.name;
        const finalFormField = field;
        const configureForm = Helper.cloneDeep(
            this.configurationForms[field.fieldType]
        );
        configureForm.fields = configureForm.fields.map(
            (each: IFormField, index) => {
                if (each.name === FieldConfig.REQUIRED_VALUE) {
                    each.value = finalFormField.required
                        ? finalFormField.required
                        : this.isRequired.has(field.name)
                        ? this.isRequired.get(field.name)
                        : false;
                    if (
                        this.isRequired.has(field.name) &&
                        this.isRequired.get(field.name)
                    ) {
                        each.disabled = true;
                    }
                }
                if (each.name === FieldConfig.LABEL_VALUE) {
                    each.value = finalFormField.label;
                    each.validations = [
                        {
                            errorMessage: 'Field label is required',
                            validator: CustomValidators.required
                        }
                    ];
                }
                if (each.name === FieldConfig.PLACE_HOLDER_VALUE) {
                    each.value = finalFormField.placeholder
                        ? finalFormField.placeholder
                        : finalFormField['placeHolder'];
                }
                if (each.name === FieldConfig.SHOW_CASE_FIELD) {
                    each.value = finalFormField.fieldType;
                }
                if (each.name === FieldConfig.FIELD_VALUE) {
                    each.value = finalFormField.value;
                    if (
                        finalFormField.fieldType ===
                            FilterType.DROPDOWN_SINGLE ||
                        finalFormField.fieldType ===
                            FilterType.DROPDOWN_MULTIPLE
                    ) {
                        each.listData = finalFormField.listData;
                    }
                }
                return each;
            }
        );
        this.configureFormInput = configureForm;
    }

    // Setting form fields name
    setFormFieldsName() {
        this.formFieldsLabel = this.sideFields.fields.map(
            (data) => data[FormFieldData.LABEL]
        );
    }

    generateConfigureButtons() {
        this.configureButtonsInput = [
            {
                buttonName: 'Save',
                buttonColorType: ButtonColorType.PRIMARY,
                buttonType: ButtonType.FLAT,
                function: (params) => {
                    const formId = this.configureFormInput.formId;
                    if (this.tempFormGroupMap.has(formId)) {
                        let mapFieldValue = this.tempFormGroupMap
                            .get(formId)
                            .getRawValue();
                        mapFieldValue = Helper.cloneDeep(mapFieldValue);
                        this.finalFormGroupMap.set(formId, mapFieldValue);
                    }
                    this.processFormValue();
                }
            },
            {
                buttonName: 'Cancel',
                buttonColorType: ButtonColorType.PRIMARY,
                function: (params) => {
                    this.configureFormInput = null;
                },
                buttonType: ButtonType.STROKED
            }
        ];
    }
    processFormValue() {
        const formId = this.configureFormInput.formId;
        if (this.finalFormGroupMap.has(formId)) {
            const mapFieldValue = this.finalFormGroupMap.get(formId);
            const field = this.configureFields.find((configureField) => {
                return configureField.name === this.currentConfigureFieldName;
            });
            field.label = mapFieldValue[FieldConfig.LABEL_VALUE];
            field.placeholder = mapFieldValue[FieldConfig.PLACE_HOLDER_VALUE];
            field.required = mapFieldValue[FieldConfig.REQUIRED_VALUE];
            field.value = mapFieldValue[FieldConfig.FIELD_VALUE];
        }
        this.configureFormInput = null;
    }

    openCaseHandlingModal() {
        const stepOneData = this.multiStepFormService.stepData
            .get(this.modalInjectedData.modalId)
            .get(1);
        const modalArgs: IModalData = {
            modalName: 'Test Case',
            modalIcon: undefined,
            modalType: ModalType.MIDDLE,
            sourceId: this.widgetRef.widgetData.widgetUniqueIdentifier,
            extraStepContainerClass: 'remove-form-space',
            modalSteps: [
                {
                    stepName: 'Test Case',
                    stepData: {
                        componentToLoad: SalesforceIntegrationCaseFormComponent,
                        payload: {
                            data: {
                                formFields: this.configureFields,
                                widgetRef: this.widgetRef,
                                stepOneData: stepOneData
                            }
                        }
                    }
                }
            ],
            modalHeightVh: 55,
            modalWidthVw: 50
        };
        this.widgetRef.modalService.openModal(modalArgs);
    }

    plotField(field: IFormField, isChecked: boolean) {
        if (this.isRequired.get(field.name)) {
            return;
        }
        if (isChecked) {
            this.configureFields.push(field);
            this.isChecked.set(field.name, true);
        } else {
            const index = this.configureFields.findIndex((configureField) => {
                return configureField.name === field.name;
            });
            if (index !== -1) {
                this.configureFields.splice(index, 1);
                this.isChecked.set(field.name, false);
            }
        }
    }
    checkFileType() {
        this.sideFields.fields.forEach((field, index) => {
            if (
                (field.fieldType === FilterType.DROPDOWN_SINGLE ||
                    field.fieldType === FilterType.DROPDOWN_MULTIPLE) &&
                (!field.listData || !field.listData.length)
            ) {
                this.sideFields.fields.splice(index, 1);
            }
        });
    }
    ngOnDestroy() {
        this.resetModal.unsubscribe();
    }
}

interface IStepData {
    form: IFormGeneratorInput;
    isRequired: Map<string, boolean>;
    configureFields: IFormField[];
    sideFields: IFormGeneratorInput;
    isChecked: Map<string, boolean>;
}
