import {
    Component,
    NgZone,
    OnInit,
    TemplateRef,
    ViewChild
} from '@angular/core';
import { GridOptions } from 'ag-grid-community';
import { Helper } from 'src/app/shared/classes/Helper';
import { HitApi } from 'src/app/shared/classes/HitApi';
import { Messages } from 'src/app/shared/classes/Messages';
import { Widget } from 'src/app/shared/classes/Widget';
import { WidgetInjectedData } from 'src/app/shared/classes/WidgetInjectedData';
import { ActionState } from 'src/app/shared/enums/ActionState';
import { ActionVisibility } from 'src/app/shared/enums/ActionVisibility';
import { ButtonColorType } from 'src/app/shared/enums/ButtonColorType';
import { ButtonType } from 'src/app/shared/enums/ButtonType';
import { IconType } from 'src/app/shared/enums/IconType';
import { ModalType } from 'src/app/shared/enums/ModalType';
import { IAction } from 'src/app/shared/interfaces/actions/IAction';
import { IButtonGeneratorInput } from 'src/app/shared/interfaces/button-generator/IButtonGeneratorInput';
import { IHitApi } from 'src/app/shared/interfaces/hit-api/IHitApi';
import { IIcon } from 'src/app/shared/interfaces/icon-data/IIcon';
import { IModalData } from 'src/app/shared/interfaces/modal/IModalData';
import { IButtonData } from 'src/app/shared/interfaces/table-generator/IButtonData';
import { ITableGeneratorInput } from 'src/app/shared/interfaces/table-generator/ITableGeneratorInput';
import { HttpService } from 'src/app/shared/services/http/http-main/http.service';
import { ModalService } from 'src/app/shared/services/modal/modal-service/modal.service';
import { McaOnboardingModalComponent } from '../../../modal-templates/account-onboarding-modals/mca-onboarding-modal/mca-onboarding-modal.component';
import { CommonMoreInfoModalComponent } from '../../../modal-templates/common-more-info-modal/common-more-info-modal.component';
import { McaOnboardingBulkUploadComponent } from './../../../modal-templates/mca-onboarding-bulk-upload/mca-onboarding-bulk-upload.component';
import { MultiButtonGeneratorComponent } from './../../../multi-button-generator/multi-button-generator.component';

@Component({
    selector: 'app-mca-listing',
    templateUrl: './mca-listing.component.html',
    styleUrls: ['./mca-listing.component.sass']
})
export class McaListingComponent implements OnInit {
    tableGenInput: ITableGeneratorInput = null;
    widgetRef: Widget;
    selectedData;
    @ViewChild('actionTemplate') actionTemplate: TemplateRef<any>;
    agGrid: GridOptions = null;
    isDeleteAction = false;

    constructor(
        widgetData: WidgetInjectedData,
        private modalService: ModalService,
        private httpService: HttpService,
        private ngZone: NgZone
    ) {
        this.widgetRef = widgetData.widgetRef;
        this.setupBasics();
    }

    ngOnInit(): void {
        this.handleBulkActions();
        this.widgetRef.refreshWidgetCallback = () => {
            this.widgetRef.showNotificationBadge.next(false);
        };
    }
    handleBulkActions() {
        const actions: IAction[] = [];
        actions.push({
            state: ActionState.ENABLED,
            visibility: ActionVisibility.VISIBLE,
            message: '',
            function: () => {
                this.deleteAccount(true);
            },
            icon: {
                type: IconType.SVG,
                class: 'trash_icon_filled',
                text: 'Bulk Delete',
                extraClass: 'inline-fix-box-1 svg-black-fill'
            }
        });
        actions.push({
            state: ActionState.ENABLED,
            visibility: ActionVisibility.VISIBLE,
            message: '',
            function: () => {
                this.bulkUpload();
            },
            icon: {
                type: IconType.SVG,
                class: 'upload',
                text: 'Bulk Upload',
                extraClass: 'inline-fix-box-1 svg-black-fill'
            }
        });
        actions.push({
            state: ActionState.ENABLED,
            visibility: ActionVisibility.VISIBLE,
            message: '',
            function: () => {
                this.suspendResumeAccount(true, true);
            },
            icon: {
                type: IconType.SVG,
                class: 'suspend',
                text: 'Bulk Suspend',
                extraClass: 'inline-fix-box-1 svg-black-fill'
            }
        });
        actions.push({
            state: ActionState.ENABLED,
            visibility: ActionVisibility.VISIBLE,
            message: '',
            function: () => {
                this.suspendResumeAccount(false, true);
            },
            icon: {
                type: IconType.SVG,
                class: 'resume',
                text: 'Bulk Resume',
                extraClass: 'inline-fix-box-1 svg-black-fill'
            }
        });
        this.widgetRef.operationalActions.value.set('Bulk Action', actions);
        this.widgetRef.operationalActions.next(
            this.widgetRef.operationalActions.value
        );
    }
    setupBasics() {
        this.tableGenInput = {
            buttons: [
                {
                    buttonName: 'Add MCA Account',
                    buttonType: ButtonType.FLAT,
                    buttonColorType: ButtonColorType.PRIMARY,
                    function: this.openOnboardingModal.bind(this)
                },
                {
                    buttonName: 'Update Linked Accounts',
                    function: (buttonRef: IButtonData) => {
                        buttonRef.loader = true;
                        const args: IHitApi = Helper.generateHitApiConfig(
                            this.widgetRef.widgetData.widgetInfo[
                                'updateLinkedAccounts'
                            ]
                        );
                        args.input = {};
                        args.function = (response) => {
                            buttonRef.loader = false;
                            this.widgetRef.notificationsService.showSnackBar(
                                Messages.ACCOUNT_UPDATE_LIST
                            );
                            this.widgetRef.attentionRequired(response);
                            this.widgetRef.refreshWidget();
                        };
                        args.errorFunction = (error) => {
                            buttonRef.loader = false;
                            this.widgetRef.changeDetectorRef.detectChanges();
                            this.widgetRef.notificationsService.showSnackBar(
                                error.error.message,
                                true
                            );
                        };
                        new HitApi(
                            args,
                            this.httpService,
                            this.ngZone
                        ).hitApi();
                    },
                    showLoader: true,
                    buttonType: ButtonType.FLAT,
                    buttonColorType: ButtonColorType.PRIMARY
                }
            ],
            listExtraction: {
                type: 'DIRECT'
            },
            selection: 'multiple',
            columns: [
                {
                    columnName: '',
                    columnKey: '',
                    pinned: 'left',
                    maxWidth: 30,
                    filter: false,
                    cellClass: 'status-container',
                    cellRenderer: (rowData) => {
                        if (rowData.data.status === 'ACTIVE') {
                            return `<span>
                            <i class="fas fa-circle green-severity-color" title="ACTIVE"></i>
                                        </span>`;
                        } else {
                            return `<span>
                            <i class="fas fa-circle red-severity-color" title="INACTIVE"></i>
                                        </span>`;
                        }
                    }
                },
                {
                    columnName: 'Billing Account ID',
                    columnKey: 'accountNumber',
                    cellRenderer: (rowData) => {
                        return rowData.data['accountNumber']
                            ? rowData.data['accountNumber']
                            : '-';
                    },
                    columnValueGetter: (rowData) => {
                        return rowData.data['accountNumber']
                            ? rowData.data['accountNumber']
                            : '-';
                    },
                    pinned: 'left'
                },
                {
                    columnName: 'Subscription ID',
                    columnKey: 'subscriptionId',
                    cellRenderer: (rowData) => {
                        if (rowData.data['type'] === 'MASTER') {
                            return '-';
                        } else {
                            if (
                                rowData.data['mcaAccountsMoreInfo'] &&
                                rowData.data['mcaAccountsMoreInfo'][
                                    'subscriptionInfo'
                                ] &&
                                rowData.data['mcaAccountsMoreInfo'][
                                    'subscriptionInfo'
                                ].length > 0
                            ) {
                                return rowData.data['mcaAccountsMoreInfo'][
                                    'subscriptionInfo'
                                ][0]['subscriptionId'];
                            }
                        }
                    },
                    columnValueGetter: (rowData) => {
                        if (rowData.data['type'] === 'MASTER') {
                            return '-';
                        } else {
                            if (
                                rowData.data['mcaAccountsMoreInfo'] &&
                                rowData.data['mcaAccountsMoreInfo'][
                                    'subscriptionInfo'
                                ] &&
                                rowData.data['mcaAccountsMoreInfo'][
                                    'subscriptionInfo'
                                ].length > 0
                            ) {
                                return rowData.data['mcaAccountsMoreInfo'][
                                    'subscriptionInfo'
                                ][0]['subscriptionId'];
                            }
                        }
                    }
                },
                {
                    columnName: 'Account Type',
                    columnKey: 'type',
                    cellRenderer: (rowData) => {
                        return rowData.data['type']
                            ? rowData.data['type']
                            : '-';
                    },
                    columnValueGetter: (rowData) => {
                        return rowData.data['type']
                            ? rowData.data['type']
                            : '-';
                    }
                },
                {
                    columnName: 'Account Name',
                    columnKey: 'accountName',
                    cellRenderer: (rowData) => {
                        return rowData.data['accountName']
                            ? rowData.data['accountName']
                            : '-';
                    },
                    columnValueGetter: (rowData) => {
                        return rowData.data['accountName']
                            ? rowData.data['accountName']
                            : '-';
                    }
                },
                {
                    columnName: 'Account SPOC',
                    columnKey: 'spoc',
                    cellRenderer: (rowData) => {
                        return rowData.data['spoc']
                            ? rowData.data['spoc']
                            : '-';
                    },
                    columnValueGetter: (rowData) => {
                        return rowData.data['spoc']
                            ? rowData.data['spoc']
                            : '-';
                    }
                },
                {
                    columnName: 'Account Status',
                    columnKey: 'status',
                    cellRenderer: (rowData) => {
                        return rowData.data['status']
                            ? rowData.data['status']
                            : '-';
                    },
                    columnValueGetter: (rowData) => {
                        return rowData.data['status']
                            ? rowData.data['status']
                            : '-';
                    }
                },
                {
                    columnName: 'Action',
                    columnKey: 'action',
                    pinned: 'right',
                    cellClass: 'action-container',
                    minWidth: 290,
                    headerClass: 'grid-cell-data-centred',
                    filter: false,
                    buttonGen: true,
                    componentFramework: MultiButtonGeneratorComponent,
                    valueFormatter: (rowData) => {
                        rowData['buttonGenInputs'] = [
                            {
                                buttonName: 'Edit',
                                showLoader: true,
                                buttonColor: 'blueInfoColor',
                                buttonColorType: ButtonColorType.INFO,
                                buttonType: ButtonType.TEXT,
                                function: (data: IButtonData) => {
                                    this.openOnboardingModal(
                                        rowData.data,
                                        true
                                    );
                                }
                            },
                            {
                                buttonName: 'Delete',
                                buttonColorType: ButtonColorType.WARN,
                                buttonType: ButtonType.TEXT,
                                showLoader: false,
                                buttonColor: 'redActionColor',
                                function: () => {
                                    this.selectedData = [rowData.data];
                                    this.deleteAccount();
                                }
                            },
                            {
                                buttonName:
                                    rowData.data.status === 'ACTIVE'
                                        ? 'Suspend'
                                        : 'Resume',
                                showLoader: false,
                                buttonColor: 'greenActionColor',
                                buttonColorType: ButtonColorType.SUCCESS,
                                buttonType: ButtonType.TEXT,
                                function: () => {
                                    this.selectedData = [rowData.data];
                                    this.suspendResumeAccount(
                                        rowData.data.status === 'ACTIVE'
                                            ? true
                                            : false
                                    );
                                }
                            },
                            {
                                buttonName: 'More Info',
                                showLoader: true,
                                buttonColorType: ButtonColorType.INFO,
                                buttonType: ButtonType.TEXT,
                                buttonColor: 'blueInfoColor',
                                function: (
                                    buttonRef: IButtonGeneratorInput
                                ) => {
                                    if (
                                        rowData.data['type'] !== 'MASTER' &&
                                        rowData.data['mcaAccountsMoreInfo']
                                    ) {
                                        this.openMoreInfo(
                                            rowData.data['mcaAccountsMoreInfo']
                                        );
                                    } else {
                                        buttonRef.loader = true;
                                        this.fetchMoreInfo(
                                            rowData.data,
                                            (error, response) => {
                                                buttonRef.loader = false;
                                                if (
                                                    !error &&
                                                    response &&
                                                    response[
                                                        'mcaAccountsMoreInfo'
                                                    ]
                                                ) {
                                                    this.openMoreInfo(
                                                        response[
                                                            'mcaAccountsMoreInfo'
                                                        ]
                                                    );
                                                }
                                                this.widgetRef.changeDetectorRef.detectChanges();
                                            }
                                        );
                                    }
                                }
                            }
                        ];
                        return rowData;
                    }
                }
            ]
        };
    }
    openOnboardingModal(stepData, editData?: boolean) {
        const moreInfoIcon: IIcon = {
            type: IconType.FONTAWSOME,
            class: ''
        };
        const modalData: IModalData = {
            modalName: 'OnBoard Azure MCA Account',
            modalIcon: moreInfoIcon,
            modalType: ModalType.MIDDLE,
            modalHeightVh: 95,
            sourceId: this.widgetRef.uniqueIdentity,
            modalWidthVw: 75,
            modalSteps: [
                {
                    stepName: 'Step 1',
                    resetModal: true,
                    stepData: {
                        componentToLoad: McaOnboardingModalComponent,
                        payload: {
                            data: {
                                widgetRef: this.widgetRef,
                                stepData: editData ? stepData : null,
                                edit: editData ? true : false
                            }
                        }
                    }
                }
            ]
        };
        this.modalService.openModal(modalData);
    }
    bulkUpload() {
        if (!this.agGrid.api.getSelectedNodes().length) {
            Helper.showErrorMessage(
                this.widgetRef.notificationsService,
                null,
                'Select at least one entry to perform the action'
            );
            return;
        }

        this.selectedData = this.agGrid.api
            .getSelectedNodes()
            .map((rowData) => {
                return rowData.data;
            });

        if (!this.checkIfSingleTypeSelected()) {
            Helper.showErrorMessage(
                this.widgetRef.notificationsService,
                null,
                'Only select one type of accounts to perform this action'
            );
            return;
        }
        const moreInfoIcon: IIcon = {
            type: IconType.FONTAWSOME,
            class: ''
        };
        const modalData: IModalData = {
            modalName: 'Bulk Upload Azure MCA Account',
            modalIcon: moreInfoIcon,
            modalType: ModalType.MIDDLE,

            sourceId: this.widgetRef.uniqueIdentity,
            modalWidthVw: 90,

            modalAutoHeight: true,
            modalSteps: [
                {
                    stepName: 'Step 1',
                    resetModal: true,
                    stepData: {
                        componentToLoad: McaOnboardingBulkUploadComponent,
                        payload: {
                            data: {
                                widgetRef: this.widgetRef,
                                selectedTableData: this.selectedData
                            }
                        }
                    }
                }
            ]
        };
        this.modalService.openModal(modalData);
    }
    deleteAccount(bulkDeleteSelected?) {
        this.isDeleteAction = true;
        if (bulkDeleteSelected && !this.agGrid.api.getSelectedNodes().length) {
            Helper.showErrorMessage(
                this.widgetRef.notificationsService,
                null,
                'Select at least one entry to perform the action'
            );
            return;
        }
        if (bulkDeleteSelected) {
            this.selectedData = this.agGrid.api
                .getSelectedNodes()
                .map((rowData) => {
                    return rowData.data;
                });
        }

        if (!this.checkIfSingleTypeSelected()) {
            Helper.showErrorMessage(
                this.widgetRef.notificationsService,
                null,
                'Only select one type of accounts to perform this action'
            );
            return;
        }

        const deleteIcon: IIcon = {
            class: '',
            type: IconType.FONTAWSOME,
            extraClass: 'text'
        };
        this.modalService.openConfirmationModal({
            modalName: 'Delete Azure MCA Account',
            modalIcon: deleteIcon,
            hideCancelButton: true,
            confirmationMessage:
                'Are you sure you want to delete the following Acount?',
            buttonText: 'Confirm',
            buttonColorType: ButtonColorType.PRIMARY,
            loaderOnExec: true,
            contextIcon: deleteIcon,
            modalAutoHeight: true,
            modalWidthVw: this.selectedData[0]['type'] === 'MASTER' ? 70 : 50,

            bodyTemplate: this.actionTemplate,
            function: (modalId: Symbol) => {
                const apiArgs: IHitApi = Helper.generateHitApiConfig(
                    this.widgetRef.widgetData.widgetInfo.delete
                );
                apiArgs.input = this.createPayload();
                apiArgs.function = (response) => {
                    this.widgetRef.notificationsService.showSnackBar(
                        'Account Deleted Successfully'
                    );
                    this.isDeleteAction = false;
                    this.widgetRef.refreshWidget();
                    this.widgetRef.modalService.closeModal(null, modalId);
                };
                apiArgs.errorFunction = (error) => {
                    this.widgetRef.notificationsService.showSnackBar(
                        error && error.error && error.error.message
                            ? error.error.message
                            : 'Error Deleting Account'
                    );
                    this.isDeleteAction = false;
                    this.widgetRef.refreshWidget();
                    this.widgetRef.modalService.closeModal(null, modalId);
                };

                new HitApi(
                    apiArgs,
                    this.widgetRef.httpService,
                    this.widgetRef.ngZone
                ).hitApi();
            }
        });
    }
    suspendResumeAccount(suspend, bulkActionSelected?) {
        if (bulkActionSelected && !this.agGrid.api.getSelectedNodes().length) {
            Helper.showErrorMessage(
                this.widgetRef.notificationsService,
                null,
                'Select at least one entry to perform the action'
            );
            return;
        }
        if (bulkActionSelected) {
            this.selectedData = this.agGrid.api
                .getSelectedNodes()
                .map((rowData) => {
                    return rowData.data;
                });
        }

        if (!this.checkIfSingleTypeSelected()) {
            Helper.showErrorMessage(
                this.widgetRef.notificationsService,
                null,
                'Only select one type of accounts to perform this action'
            );
            return;
        }

        const icon: IIcon = {
            type: IconType.FONTAWSOME,
            class: ''
        };

        this.modalService.openConfirmationModal({
            confirmationMessage: `Are you sure you want to ${
                suspend ? 'suspend' : 'resume'
            } the folllowing Account?`,
            buttonText: 'Confirm',
            buttonColorType: ButtonColorType.PRIMARY,
            hideCancelButton: true,
            modalIcon: icon,
            modalAutoHeight: true,
            loaderOnExec: true,
            bodyTemplate: this.actionTemplate,
            modalName: suspend
                ? 'Suspend Azure MCA Account'
                : 'Resume Azure MCA Account',
            contextIcon: icon,
            function: (modalId: Symbol) => {
                const apiArgs: IHitApi = Helper.generateHitApiConfig(
                    this.widgetRef.widgetData.widgetInfo['suspendUnsuspend']
                );
                apiArgs.input = this.createPayload(suspend);
                apiArgs.url = apiArgs.url;

                apiArgs.function = (response) => {
                    this.widgetRef.notificationsService.showSnackBar(
                        suspend ? 'Account Suspended' : 'Account Resumed'
                    );
                    this.widgetRef.refreshWidget();
                    this.widgetRef.modalService.closeModal(null, modalId);
                };
                apiArgs.errorFunction = (error) => {
                    Helper.showErrorMessage(
                        this.widgetRef.notificationsService,
                        error,
                        `${
                            error && error.error && error.error.message
                                ? error.error.message
                                : `'Error' ${
                                      suspend ? 'suspending' : 'resuming'
                                  } accounts`
                        }`
                    );
                    this.widgetRef.refreshWidget();
                    this.widgetRef.modalService.closeModal(null, modalId);
                };

                new HitApi(
                    apiArgs,
                    this.widgetRef.httpService,
                    this.widgetRef.ngZone
                ).hitApi();
            }
        });
    }
    onSelectionChanged() {
        this.agGrid.api.getSelectedNodes().length
            ? this.widgetRef.showNotificationBadge.next(true)
            : this.widgetRef.showNotificationBadge.next(false);
    }
    checkIfSingleTypeSelected() {
        return (
            this.selectedData.every((data) => data['type'] === 'MASTER') ||
            this.selectedData.every((data) => data['type'] === 'SUBSCRIPTION')
        );
    }
    createPayload(suspend?) {
        const payLoadData = {
            accountType: this.selectedData[0]['type'],
            accountStatus: suspend ? 'INACTIVE' : 'ACTIVE',
            accountIds: this.selectedData.map((data) => {
                if (this.selectedData[0]['type'] === 'MASTER') {
                    return data['accountNumber'];
                } else {
                    return data['mcaAccountsMoreInfo']['subscriptionInfo'][0][
                        'subscriptionId'
                    ];
                }
            })
        };

        return payLoadData;
    }

    openMoreInfo(inputData) {
        const moreInfoIcon: IIcon = {
            type: IconType.FONTAWSOME,
            class: ''
        };
        const modalData: IModalData = {
            modalName: '',
            noHeader: true,
            modalType: ModalType.SIDE,
            sourceId: this.widgetRef.uniqueIdentity,
            modalIcon: moreInfoIcon,
            noStepPadding: true,
            modalSteps: [
                {
                    stepName: 'step 1',
                    stepData: {
                        componentToLoad: CommonMoreInfoModalComponent,
                        payload: {
                            data: {
                                stepData: inputData,
                                widgetRef: this.widgetRef
                            }
                        }
                    }
                }
            ]
        };
        this.modalService.openModal(modalData);
    }

    fetchMoreInfo(inputData, callback) {
        let apiInfo = null;
        if (
            this.widgetRef.widgetData.widgetInfo['additionalApisForWidget'] &&
            this.widgetRef.widgetData.widgetInfo['additionalApisForWidget'][
                'widgetMoreInfo'
            ]
        ) {
            apiInfo =
                this.widgetRef.widgetData.widgetInfo['additionalApisForWidget'][
                    'widgetMoreInfo'
                ];
        }
        if (apiInfo && inputData) {
            const apiArgs = Helper.generateHitApiConfig(apiInfo);
            apiArgs.input = inputData;
            apiArgs.function = (response) => {
                callback(null, response);
            };
            apiArgs.errorFunction = (error) => {
                Helper.showErrorMessage(
                    this.widgetRef.notificationsService,
                    error,
                    'Error while loading more info'
                );
                callback(error);
            };
            new HitApi(
                apiArgs,
                this.widgetRef.httpService,
                this.widgetRef.ngZone
            ).hitApi();
        }
    }
}
