<app-table-generator
    [tableInput]="tableGenInput"
    [widgetRef]="widgetRef"
    (gridRef)="agGrid = $event"
    (selectionChanged)="onSelectionChanged()"
    [showPagination]="true"
></app-table-generator>
<ng-template #accounts>
    <div class="text-container">
        <li class="text" *ngFor="let account of selectedAccounts">
            {{ account.rootUserName }}
        </li>
    </div>
</ng-template>
