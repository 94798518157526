import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    NgZone,
    OnChanges,
    OnInit,
    Output,
    TemplateRef,
    ViewChild,
} from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { GridApi, GridOptions, RowNode } from 'ag-grid-community';
import { BehaviorSubject } from 'rxjs';
import { ApiUrls } from 'src/app/core/classes/ApiUrls';
import { HitApi } from 'src/app/shared/classes/HitApi';
import { Messages } from 'src/app/shared/classes/Messages';
import { AuthorizationType } from 'src/app/shared/enums/AuthorizationType';
import { IconType } from 'src/app/shared/enums/IconType';
import { RequestType } from 'src/app/shared/enums/RequestType';
import { ApiResponseType } from 'src/app/shared/interfaces/api/portlets/ApiResponse';
import { IHitApi } from 'src/app/shared/interfaces/hit-api/IHitApi';
import { IIcon } from 'src/app/shared/interfaces/icon-data/IIcon';
import { WidgetHttpService } from 'src/app/shared/services/http/widget-http/widget-http.service';
import { NotificationsService } from 'src/app/shared/services/notifications/notifications.service';
import { Helper } from '../../classes/Helper';
import { Widget } from '../../classes/Widget';
import { ActionVisibility } from '../../enums/ActionVisibility';
import { FileType } from '../../enums/FileType';
import { ViewType } from '../../enums/ViewType';
import { IFilterInfo } from '../../interfaces/filter/IFilterInfo';
import { IColumnData } from '../../interfaces/table-generator/IColumnData';
import {
    ITableGeneratorExtraComponents,
    ITableGeneratorInput,
} from '../../interfaces/table-generator/ITableGeneratorInput';
import { PaginationValues } from '../../enums/PaginationValues';

@Component({
    selector: 'app-table-generator',
    templateUrl: './table-generator.component.html',
    styleUrls: ['./table-generator.component.sass'],
})
export class TableGeneratorComponent
    implements OnInit, OnChanges, AfterViewInit
{
    @Input() widgetRef: Widget;
    @Input() tableInput: ITableGeneratorInput;
    @Input() tableData: BehaviorSubject<any>;
    @Input() overviewTemplateStart: TemplateRef<any>;
    @Input() overviewTemplateEnd: TemplateRef<any>;
    @Input() overviewTemplateBottom: TemplateRef<any>;
    @Input() useMaterialIcons: boolean = false;
    @Input() isRowSelectable: Function = null;
    @Input() showSearchBox: boolean = true;
    @Input() showPagination?: boolean;
    @Output() gridRef = new EventEmitter<GridOptions>();
    @Output() cellValueChanged = new EventEmitter<GridOptions>();
    @Output() selectionChanged = new EventEmitter<GridOptions>();
    @ViewChild('agGrid', { static: false }) agGrid: GridOptions;
    @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;
    private readonly statusPayloadAzureEaWidgetId = 'b68abbb9-f27c-4e2a-9304-e21f429fc191'

    ViewType = ViewType;
    FileType = FileType;
    columnOrder;
    disabledColumns = [];
    toggleEnabled: Map<string, boolean> = new Map<string, boolean>();
    columnsOrderable: boolean = false;
    showGrid = true;
    loading = true;
    pagination: boolean = false;
    paginationPageSize: string = null;
    paginationDropdownInfo: IFilterInfo = {
        label: 'Page Size',
        listData: null,
        selectedValue: null,
    };
    paginationSelectedValue = null;
    pageFlow = PageFlow;

    private gridApi: GridApi;
    private gridColumnApi;
    agGridIcons = Helper.getAgGridIcons();
    colOrderIcon: IIcon = {
        type: IconType.SVG,
        class: 'columnOrdering',
    };
    dragIcon: IIcon = {
        type: IconType.SVG_ASSETS,
        class: 'dragIcon',
    };

    selectionChangeActive = true;
    selectAll = false;
    rowData;
    colDefs;
    newColDef;
    unchangedColDef;
    defaultColDef = {
        sortable: true,
        filter: true,
        resizable: true,
        width: 150,
    };
    streamAttentionRequiredData = {
        generalException: [],
        insufficientPermission: [],
        invalidCredentials: [],
        noCredentialsDB: [],
    };
    columnName: string[] = [];
    fixedColumns: string[] = [];
    tableAutoHeight = false;
    tableId = Helper.generateUniqueKey(8);
    frameworkComponents: ITableGeneratorExtraComponents;

    public paginationValues: {
        startItem: number;
        endItem: number;
        totalRecords: number;
        totalPages: number;
    } = {
        startItem: 0,
        endItem: 0,
        totalRecords: 0,
        totalPages: 0,
    };
    public inputValue = 1;
    public selectedOption = 20;
    public pageNumber = 1;
    private prevPageNumber = 1;
    public searchedValue = '';

    constructor(
        private ngZone: NgZone,
        private widgetHttpService: WidgetHttpService,
        private notificationsService: NotificationsService,
        private changeDetector: ChangeDetectorRef,
    ) {}

    ngOnInit(): void {
        this.setUpBasics();
    }

    ngOnChanges(changes) {
        if (this.agGrid) {
            this.agGrid.api.redrawRows();
            this.agGrid.api.paginationGetTotalPages;
        }
    }

    ngAfterViewInit(): void {
        if (
            this.widgetRef.widgetData.widgetInfo.list &&
            this.widgetRef.widgetData.widgetInfo.list.streamHost &&
            this.widgetRef.widgetData.widgetInfo.list.streamHost.length &&
            this.widgetRef.widgetData.widgetInfo.list.streamRouteSuffix &&
            this.widgetRef.widgetData.widgetInfo.list.streamRouteSuffix.length
        ) {
            this.widgetRef.refreshWidgetCallback =
                this.refreshWidgetCallback.bind(this);
            this.widgetRef.setBindPartialData(this.bindPartialData.bind(this));
        } else if (
            this.widgetRef.widgetData.widgetInfo.list.streamRequestType ===
            'IOT_STREAM'
        ) {
            this.widgetRef.refreshWidgetCallback =
                this.refreshWidgetCallback.bind(this);
            this.widgetRef.setBindPartialData(this.bindPartialData.bind(this));
        } else {
            this.widgetRef.refreshWidgetCallback =
                this.refreshWidgetCallbackViaHttp.bind(this);
            if (this.tableData) {
                this.tableData.subscribe((res) => {
                    if (res) {
                        this.bindData(res);
                    } else {
                        if (this.showPagination) {
                            this.widgetRef.onChangePagination(
                                this.bindData.bind(this),
                                this.pageNumber,
                                this.selectedOption
                            );
                        } else {
                            this.widgetRef.setBindData(
                                this.bindData.bind(this)
                            );
                        }
                    }
                });
            } else {
                if (this.showPagination) {
                    this.widgetRef.onChangePagination(
                        this.bindData.bind(this),
                        this.pageNumber,
                        this.selectedOption
                    );
                } else {
                    this.widgetRef.setBindData(this.bindData.bind(this));
                }
            }
        }
    }
    private timeOut:number = 0;
    onPageChange(e?:KeyboardEvent) {
        const setTimeoutTime = (e && e.key==="Enter")?0:3000
        if(this.timeOut){
            clearTimeout(this.timeOut);
        }
        this.timeOut = window.setTimeout(()=>{
            if (
                this.pageNumber > this.paginationValues.totalPages ||
                !this.pageNumber
            ) {
                this.notificationsService.showSnackBar(
                    'Please Enter a Valid Page Number',
                    true
                );
                    this.pageNumber = this.prevPageNumber
                    this.changeDetector.detectChanges()
                return;
            }
            else if (
                this.pageNumber<=0) {
                this.notificationsService.showSnackBar(
                    'Please Enter a Valid Page Number',
                    true
                );
                    this.pageNumber = this.prevPageNumber
                    this.changeDetector.detectChanges()
                return;
            }
            else if(!Number.isInteger(this.pageNumber)){
                this.pageNumber = this.prevPageNumber;
                this.changeDetector.detectChanges()
            }
            else {
                this.navigatePage();
            }
        },setTimeoutTime)
        }
    
        /**
         * function handles pagination for widgets in which pagination functionality is implemented
         * @param value  - reflects the action like nextPage, previousPage, lastPage or firstPage
         */
        navigatePage(value?: string | number) {
                if (value === PaginationValues.NEXTPAGE) {
                    if (this.paginationValues.totalPages === this.pageNumber) {
                        return;
                    } else {
                        this.pageNumber = this.pageNumber + 1;
                    }
                }
                if (value === PaginationValues.PREVIOUSPAGE) {
                    if (this.pageNumber === 1) {
                        return;
                    } else {
                        this.pageNumber = this.pageNumber - 1;
                    }
                }
                if (value === PaginationValues.FIRSTPAGE) {
                    if (this.pageNumber === 1) {
                        return;
                    } else {
                        this.pageNumber = 1;
                    }
                }
                if (value === PaginationValues.LASTPAGE) {
                    if (this.paginationValues.totalPages === this.pageNumber) {
                        return;
                    } else {
                        this.pageNumber = this.paginationValues.totalPages;
                    }
                }
            this.prevPageNumber = this.pageNumber
            if (this.searchedValue) {
                this.onPaginationSearch(decodeURI(this.searchedValue),true);
            } else {
                this.widgetRef.onChangePagination(
                    this.bindData.bind(this),
                    this.pageNumber,
                    this.selectedOption
                );
            }
        }

    setUpBasics() {
        // Show View Icon
        this.widgetRef.showViewIcon.next(
            this.tableInput.showViewIcon ? this.tableInput.showViewIcon : false
        );

        // Update Header Icon
        if (this.tableInput.widgetIconData) {
            this.widgetRef.headerIcon.next(this.tableInput.widgetIconData);
        }

        // Setting Visible Sections
        const visibleSections: Set<ViewType> = this.widgetRef.visibleSections
            .value
            ? this.widgetRef.visibleSections.value
            : new Set();
        visibleSections.add(ViewType.TABLE);
        this.widgetRef.visibleSections.next(visibleSections);
        //  Checking column Ordering enabled
        if (this.widgetRef.widgetData.widgetInfo.columnsOrderable) {
            this.columnsOrderable = true;
            if (
                this.widgetRef.widgetData.widgetInfo['extraInfo'][
                    'columnsStatic'
                ]
            ) {
                this.fixedColumns =
                    this.widgetRef.widgetData.widgetInfo['extraInfo'][
                        'columnsStatic'
                    ];
            }
        }

        this.frameworkComponents = this.tableInput.extraComponents;
        // Checking Lite State
        if (this.widgetRef.lightState) {
            this.setUpRowClassRules();
        }
    }
    setUpRowClassRules() {
        this.defaultColDef.sortable = false;
        this.defaultColDef.filter = false;
        Helper.setRowClassRules(
            this.widgetRef,
            this.tableInput.rowsToShow ? this.tableInput.rowsToShow : null
        );
    }

    onGridReady(params) {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
        if (
            this.widgetRef.visibleSections.value.has(ViewType.TABLE) &&
            this.widgetRef.widgetContentVisible.value &&
            !this.tableInput.unfitColumns
        ) {
            this.handleResize();
        }

        this.gridRef.emit(this.agGrid);
    }

    onCellValueChanged(params) {
        this.cellValueChanged.emit(params);
    }

    onSelectionChanged($event) {
        if (!this.selectionChangeActive) {
            return;
        }

        if (this.tableInput.selectionLimit !== undefined) {
            if (
                $event.api.getSelectedNodes().length >
                this.tableInput.selectionLimit
            ) {
                this.selectionChangeActive = false;
                this.selectAll = !this.selectAll;
                // Select All Case
                if (this.agGrid && this.agGrid.api && this.selectAll) {
                    this.agGrid.api.deselectAll();
                    const nodes: RowNode[] = (this.agGrid.api as any)[
                        'rowModel'
                    ]['rowsToDisplay'].slice(0, this.tableInput.selectionLimit);
                    let index = 0;
                    for (const node of nodes) {
                        if (index === nodes.length - 1) {
                            node.setSelected(true);
                        } else {
                            node.setSelected(true, false, true);
                        }
                        ++index;
                    }
                    this.selectionChangeActive = true;
                    this.selectionChanged.emit($event);
                    this.agGrid.api.redrawRows();
                } else {
                    this.selectionChangeActive = true;
                    this.agGrid.api.deselectAll();
                    this.selectionChanged.emit($event);
                    this.agGrid.api.redrawRows();
                }
            } else if (
                $event.api.getSelectedNodes().length ===
                this.tableInput.selectionLimit
            ) {
                this.notificationsService.showSnackBar(
                    `${
                        this.tableInput.selectionLimitBreachMessage
                            ? this.tableInput.selectionLimitBreachMessage
                            : `Maximum of ${this.tableInput.selectionLimit} row can be selected`
                    }`
                );
                this.selectionChangeActive = true;
                this.selectionChanged.emit($event);
                this.agGrid.api.redrawRows();
            } else {
                this.selectionChangeActive = true;
                this.selectionChanged.emit($event);
                this.agGrid.api.redrawRows();
            }
        } else {
            this.selectionChangeActive = true;
            this.selectionChanged.emit($event);
        }

        if (this.tableInput.showNotificationBadgeOnSelection) {
            this.agGrid.api.getSelectedNodes().length
                ? this.tableInput.showNotificationBadgeOnSelection.next(true)
                : this.tableInput.showNotificationBadgeOnSelection.next(false);
        }
    }

    bindData(data: ApiResponseType): void {
        if (data) {
            //  Get column Order
            if (this.columnsOrderable) {
                this.getColumnOrder();
            }

            this.rowData = [];
            this.colDefs = [];

            if (this.showPagination) {
                this.paginationValues.startItem = data['startItem'];
                this.paginationValues.endItem = data['endItem'];
                this.paginationValues.totalRecords = data['totalElements'];
                this.paginationValues.totalPages = data['totalPages'];
                this.pageNumber = data['currentPage'];
                this.selectedOption = data['pageSize'];
                if(this.searchedValue) {
                    this.searchedValue = '';
                }
            }
            this.widgetRef.apiResponse = this.showPagination? data['content'] : data;
            if (
                (data['generalException'] && data['generalException'].length) ||
                (data['insufficientPermission'] &&
                    data['insufficientPermission'].length) ||
                (data['invalidCredentials'] &&
                    data['invalidCredentials'].length) ||
                (data['noCredentialsDB'] && data['noCredentialsDB'].length)
            ) {
                this.widgetRef.attentionRequired(data);
            }

            if (this.tableInput.afterResponse) {
                this.tableInput.afterResponse(this.showPagination? data['content'] : data);
            }

            if (this.tableInput.listExtraction.type === 'DIRECT') {
                this.rowData = this.showPagination? data['content'] : data;
            } else {
                this.rowData = Helper.extractDataFromObject(
                    this.tableInput.listExtraction.nestedKey,
                    this.showPagination ? data['content'] : data
                );
            }
            if (!this.rowData || !this.rowData.length) {
                this.widgetRef.extraMessage.next(
                    this.tableInput.noDataMessage
                        ? this.tableInput.noDataMessage
                        : Messages.NO_DATA_AVAILABLE
                );
                this.widgetRef.visibleSections.next(new Set());
                this.widgetRef.endLoader();
                return;
            }

            this.widgetRef.visibleSections.next(new Set([ViewType.TABLE]));
            this.setColumns(this.showPagination? data['content'] : data);
            this.widgetRef.endLoader();
            this.loading = false;
        }
    }

    setColumns(data: ApiResponseType) {
        this.colDefs = [];

        const handleColumns = (columns: IColumnData[]) => {
            columns.forEach((column) => {
                const colDefObj = {
                    headerName: column.columnName,
                    field: column.columnKey,
                    colId: column.columnKey,
                };

                if (
                    column.columnName === 'Action' ||
                    column.columnName === 'More Info'
                ) {
                    colDefObj['filter'] = false;
                    colDefObj['lockPinned'] = true;
                }

                if (!column.buttonGen && column.cellRenderer) {
                    colDefObj['cellRenderer'] = column.cellRenderer;
                }
                if (column.cellRendererParams) {
                    colDefObj['cellRendererParams'] = column.cellRendererParams;
                }
                if (column.pinned) {
                    colDefObj['pinned'] = column.pinned;
                }

                if (column.filter !== undefined) {
                    colDefObj['filter'] = column.filter;
                }

                if (column.editable) {
                    colDefObj['editable'] = column.editable;
                }

                if (column.cellStyle) {
                    colDefObj['cellStyle'] = column.cellStyle;
                }

                if (column.minWidth) {
                    colDefObj['minWidth'] = column.minWidth;
                }
                if (column.maxWidth) {
                    colDefObj['maxWidth'] = column.maxWidth;
                }

                if (column.headerComponentFramework) {
                    colDefObj['headerComponent'] =
                        column.headerComponentFramework;
                }

                if (column.headerComponentParams) {
                    colDefObj['headerComponentParams'] =
                        column.headerComponentParams;
                }

                if ('columnVisibility' in column) {
                    colDefObj['hide'] = !column.columnVisibility;
                }

                if (column.suppressMovable) {
                    colDefObj['suppressMovable'] = column.suppressMovable;
                }

                if (column.hideFilterMenu) {
                    colDefObj['suppressFilter'] = column.hideFilterMenu;
                }
                if (column.columnValueGetter) {
                    colDefObj['valueGetter'] = column.columnValueGetter;
                }
                if (column.headerClass) {
                    colDefObj['headerClass'] = column.headerClass;
                }

                if (column.sortable !== undefined) {
                    colDefObj['sortable'] = column.sortable;
                }
                if (column.cellClass !== undefined) {
                    colDefObj['cellClass'] = column.cellClass;
                }

                if (column.flex) {
                    colDefObj['flex'] = column.flex;
                }
                if (column.lockPinned) {
                    colDefObj['lockPinned'] = column.lockPinned;
                }

                if (column.width) {
                    colDefObj['width'] = column.width;
                }

                if (column.lockPosition) {
                    colDefObj['lockPosition'] = column.lockPosition;
                }

                if (column.valueFormatter) {
                    colDefObj['valueFormatter'] = column.valueFormatter;
                }
                if (column.comparator) {
                    colDefObj['comparator'] = column.comparator;
                }
                if (column.getQuickFilterText) {
                    colDefObj['getQuickFilterText'] = column.getQuickFilterText;
                }
                if (column.valueGetter) {
                    colDefObj['valueGetter'] = column.valueGetter;
                }
                if (column.buttonGen && column.componentFramework) {
                    colDefObj['cellRenderer'] = column.buttonGenFramework
                        ? column.buttonGenFramework
                        : 'buttonGen';
                    this.frameworkComponents = {
                        ...this.frameworkComponents,
                        [colDefObj['cellRenderer']]: column.componentFramework,
                    };
                }

                this.colDefs.push(colDefObj);
                this.colDefs[0].pinned = 'left';
            });

            if (this.tableInput.selection === 'multiple') {
                this.colDefs.unshift({
                    headerName: '',
                    field: '',
                    headerCheckboxSelection:
                        this.tableInput.removeHeaderCheckBox !== undefined
                            ? !this.tableInput.removeHeaderCheckBox
                            : true,
                    checkboxSelection: true,
                    pinned: 'left',
                    lockPinned: true,
                    lockPosition: true,
                    minWidth: 45,
                    maxWidth: 45,
                    filter: false,
                    sortable: false,
                });
            }
            if (
                this.tableInput.selection === 'multiple' &&
                this.tableInput.removeHeaderCheckBox
            ) {
                this.colDefs[0]['headerCheckboxSelection'] = false;
            }
        };

        if (
            this.tableInput.columns &&
            this.tableInput.columns.length &&
            !this.tableInput.columnExtraction
        ) {
            handleColumns(this.tableInput.columns);
        } else if (this.tableInput.columnExtraction) {
            const columns: IColumnData[] = Helper.extractDataFromObject(
                this.tableInput.columnExtraction.nestedKey,
                data
            ).map((col) => {
                if (typeof col === 'string') {
                    return { columnKey: col, columnName: col };
                }
                return {
                    columnKey: col.id,
                    columnName: col.label,
                };
            });
            if (this.tableInput.columns && this.tableInput.columns.length) {
                columns.push(...this.tableInput.columns);
            }
            handleColumns(columns);
        }

        this.handlePagination();
    }

    refreshWidgetCallback() {
        this.colDefs = null;
        this.rowData = null;
        this.disabledColumns = [];
        this.resetAttentionRequiredData();
        if (this.tableInput.refreshWidgetCallback) {
            this.tableInput.refreshWidgetCallback();
        }

        if (this.tableInput.showNotificationBadgeOnSelection) {
            this.tableInput.showNotificationBadgeOnSelection.next(false);
        }
        this.widgetRef.widgetConfigState.actions.attention.visibility =
            ActionVisibility.HIDDEN;
    }

    refreshWidgetCallbackViaHttp() {
        this.disabledColumns = [];
        if (this.tableInput.refreshWidgetCallback) {
            this.tableInput.refreshWidgetCallback();
        }
    }

    bindPartialData(data: ApiResponseType): void {
        this.columnName = [];
        this.widgetRef.changeDetectorRef.detectChanges();
        let lastResponse = false;
        if (data && data['status'] && data['status'] === 'CLOSED') {
            lastResponse = true;
        }

        const visibleSections: Set<ViewType> = this.widgetRef.visibleSections
            .value
            ? this.widgetRef.visibleSections.value
            : new Set();

        visibleSections.add(ViewType.TABLE);
        if (lastResponse) {
            if (
                this.gridApi &&
                this.rowData &&
                this.gridApi.getModel() &&
                this.gridApi.getModel().getRowCount() !== this.rowData.length
            ) {
                this.gridApi.setRowData(this.rowData);
            }
            this.widgetRef.actionLoading.next(false);
            this.widgetRef.endPartialDataLoader();

            if (this.tableInput.onStreamClosed) {
                this.tableInput.onStreamClosed(this.rowData);
            }

            if (this.tableInput.handleAttentionRequired) {
                this.handleAttentionRequired();
            }

            if (this.tableInput.refreshColumnAfterResponse) {
                this.setColumns(this.rowData);
            }

            if (!this.rowData || !this.rowData.length) {
                this.widgetRef.extraMessage.next(
                    this.tableInput.noDataMessage
                        ? this.tableInput.noDataMessage
                        : Messages.NO_DATA_AVAILABLE
                );
                this.widgetRef.visibleSections.next(new Set());
                this.widgetRef.endLoader();
                return;
            }

            this.handleResize();
            if (
                this.rowData.length < 7 &&
                (this.tableInput.tableAutoHeight === undefined ||
                    this.tableInput.tableAutoHeight)
            ) {
                this.tableAutoHeight = false;
                this.agGrid.api.setDomLayout('autoHeight');
            }

            //  Get column Order
            if (this.columnsOrderable) {
                this.getColumnOrder();
            }
            this.loading = false;
        } else {
            this.widgetRef.startPartialDataLoader();
            if (this.tableInput.afterPartialResponse) {
                this.tableInput.afterPartialResponse(data);
            }
            if (!this.colDefs || !this.colDefs.length) {
                this.setColumns(data);
            }
            this.cumulateAttentionRequiredData(data);
            let rowData = null;
            if (this.tableInput.listExtraction.type === 'DIRECT') {
                rowData = data;
            } else {
                rowData = Helper.extractDataFromObject(
                    this.tableInput.listExtraction.nestedKey,
                    data
                );
            }

            const checkIfObjectIsNotEmpty = (object) => {
                return object &&
                    typeof object === 'object' &&
                    Object.keys(object).length > 0
                    ? true
                    : false;
            };

            if (rowData && rowData.length) {
                rowData = rowData.filter((object) =>
                    checkIfObjectIsNotEmpty(object)
                );
            }

            if (rowData && rowData.length) {
                this.widgetRef.endLoader();
                if (this.rowData && this.rowData.length) {
                    // this.rowData = [...this.rowData, rowData];
                    if (this.gridApi) {
                        this.gridApi.applyTransaction({
                            add: rowData,
                        });
                    }
                    this.rowData.push(...rowData);
                } else {
                    this.rowData = [...rowData];
                }
                this.widgetRef.actionLoading.next(true);
                this.widgetRef.endLoader();
            }
        }
    }

    resetAttentionRequiredData() {
        this.streamAttentionRequiredData = {
            generalException: [],
            insufficientPermission: [],
            invalidCredentials: [],
            noCredentialsDB: [],
        };
    }

    handleAttentionRequired() {
        this.streamAttentionRequiredData.generalException = Array.from(
            new Set(
                [].concat(...this.streamAttentionRequiredData.generalException)
            )
        );
        this.streamAttentionRequiredData.insufficientPermission = Array.from(
            new Set(
                [].concat(
                    ...this.streamAttentionRequiredData.insufficientPermission
                )
            )
        );
        this.streamAttentionRequiredData.invalidCredentials = Array.from(
            new Set(
                [].concat(
                    ...this.streamAttentionRequiredData.invalidCredentials
                )
            )
        );
        this.streamAttentionRequiredData.noCredentialsDB = Array.from(
            new Set(
                [].concat(...this.streamAttentionRequiredData.noCredentialsDB)
            )
        );
        if (
            this.streamAttentionRequiredData.generalException.length ||
            this.streamAttentionRequiredData.insufficientPermission.length ||
            this.streamAttentionRequiredData.invalidCredentials.length ||
            this.streamAttentionRequiredData.noCredentialsDB.length
        ) {
            this.widgetRef.attentionRequired(
                this.streamAttentionRequiredData as ApiResponseType
            );
        }
    }

    cumulateAttentionRequiredData(response) {
        if (
            response &&
            response['generalException'] &&
            response['generalException'].length
        ) {
            this.streamAttentionRequiredData.generalException.push(
                response['generalException']
            );
        }
        if (
            response &&
            response['insufficientPermission'] &&
            response['insufficientPermission'].length
        ) {
            this.streamAttentionRequiredData.insufficientPermission.push(
                response['insufficientPermission']
            );
        }
        if (
            response &&
            response['invalidCredentials'] &&
            response['invalidCredentials'].length
        ) {
            this.streamAttentionRequiredData.invalidCredentials.push(
                response['invalidCredentials']
            );
        }
        if (
            response &&
            response['noCredentialsDB'] &&
            response['noCredentialsDB'].length
        ) {
            this.streamAttentionRequiredData.noCredentialsDB.push(
                response['noCredentialsDB']
            );
        }
    }

    handleResize() {
        if (this.agGrid && this.tableId) {
            Helper.handleTableResize(this.agGrid, this.tableId);
        }
    }

    onQuickFilterChanged(id: string) {
        this.agGrid.api.setQuickFilter(document.getElementById(id)['value']);
    }

    onPaginationSearch(value:string,paginating:boolean=false) {
        if(!value) return;
        if(!paginating){
            this.pageNumber = 1
        }
        value = encodeURI(value);
        this.widgetRef.widgetData.widgetInfo
        const apiArgs = Helper.generateHitApiConfig(
            this.widgetRef.widgetData.widgetInfo.search
        );
        apiArgs.url = apiArgs.url.replace(
            '{pageNo}/{offset}',
            `${this.pageNumber}/${this.selectedOption}?search=${value}`
        );
        apiArgs.config = {
            authorization: AuthorizationType.BEARER_TOKEN,
            defaultHeaders: {
                'Content-Type': 'application/json',
            },
        };
        apiArgs.function = (response) => {
            this.searchedValue = value;
            this.paginationValues.startItem = response?.startItem;
            this.paginationValues.endItem = response?.endItem;
            this.paginationValues.totalRecords = response?.totalElements;
            this.paginationValues.totalPages = response?.totalPages;
            this.rowData = response['content'];
            this.widgetRef.changeDetectorRef.detectChanges();
            if (!this.rowData || !this.rowData.length) {
                this.widgetRef.extraMessage.next(
                    this.tableInput.noDataMessage
                        ? this.tableInput.noDataMessage
                        : Messages.NO_DATA_AVAILABLE
                );
            }
            else{
                this.widgetRef.extraMessage.next(null);
            }
        };
        apiArgs.errorFunction = (error) => {
            this.paginationValues.startItem = 0;
            this.paginationValues.endItem = 0;
            this.paginationValues.totalRecords = 0;
            this.paginationValues.totalPages = 0;
            this.rowData = [];
            this.widgetRef.changeDetectorRef.detectChanges();
        };
        if(this.showPagination && this.widgetRef.widgetData.widgetInfo.search.requestType==="POST"){
            // adding values to query parameter if widget is azure-ea listing and seach keyword is substring of status
            if(this.widgetRef.widgetData.widgetInfo['widgetId'] === this.statusPayloadAzureEaWidgetId){
                const ACTIVE = 'active';
                const INACTIVE = 'inactive';
                const isActiveStr = ACTIVE.includes(value.toLowerCase());
                const isInactiveStr = INACTIVE.includes(value.toLowerCase())
                if(!isActiveStr && isInactiveStr){
                    apiArgs.input = {status:'INACTIVE'}
                }
                else{
                    apiArgs.input = {status:'ACTIVE'};
                }
            }
            else{
                apiArgs.input = {};
            }
        }
        console.log(this.widgetRef.widgetData.widgetInfo);
        new HitApi(apiArgs, this.widgetHttpService, this.ngZone).hitApi();
    }

    getColumnOrder() {
        const apiArgs: IHitApi = {
            url: ApiUrls.COLUMN_ORDER.replace(
                '{widgetId}',
                this.widgetRef.widgetData.widgetId
            ),
            intactUrl: ApiUrls.COLUMN_ORDER,
            input: {},
            requestType: RequestType.GET,
            uniqueIdentity: Symbol(),
            function: (response) => {
                if (response) {
                    this.columnOrder = response.orderInfo;
                    if (this.columnOrder && this.columnOrder.length) {
                        this.columnOrder.forEach((data) => {
                            if (data.columnName) {
                                this.toggleEnabled.set(
                                    data.columnName,
                                    data.enabled
                                );
                            }
                        });
                    } else {
                        this.toggleEnabled.clear();
                    }
                    this.unchangedColDef = this.colDefs;
                    this.mapColumnOrder();
                    this.newColDef = this.colDefs;
                    this.setColumnNames(this.colDefs, this.disabledColumns);
                    this.saveOrder(null, true);
                }
            },
            config: {
                authorization: AuthorizationType.BEARER_TOKEN,
            },
        };

        new HitApi(
            apiArgs,
            this.widgetHttpService,
            this.widgetHttpService.ngZone
        ).hitApi();
    }

    colDrag(event) {
        if (
            this.tableInput.fixedColumnPosition &&
            this.tableInput.fixedColumnPosition.length
        ) {
            this.tableInput.fixedColumnPosition.map((column) => {
                this.agGrid.columnApi.moveColumn(
                    column.columnKey,
                    column.index === 'lastIndex'
                        ? event.columnApi.columnController.allDisplayedColumns
                              .length - 1
                        : this.tableInput.selection === 'multiple'
                        ? column.index + 1
                        : column.index
                );
            });
        }

        if (this.columnsOrderable) {
            const input = [];
            const coldef = [];
            let dataChanged = false;
            const gridData =
                event.columnApi.columnController.allDisplayedColumns;
            for (let i = 0; i < gridData.length; i++) {
                if (
                    gridData[i].colDef.headerName &&
                    gridData[i].colDef.headerName.length &&
                    (this.newColDef[i].headerName !==
                        gridData[i].colDef.headerName ||
                        this.newColDef[i].field !== gridData[i].colDef.field)
                ) {
                    dataChanged = true;
                    break;
                }
            }
            if (dataChanged) {
                for (const data of gridData) {
                    coldef.push(
                        this.colDefs[
                            this.colDefs.findIndex(
                                (field) =>
                                    field.field === data.colDef.field &&
                                    field.headerName === data.colDef.headerName
                            )
                        ]
                    );
                }

                let difference;
                if (coldef.length !== this.colDefs.length) {
                    difference = this.colDefs.filter(
                        (element) => !coldef.includes(element)
                    );
                }
                if (difference) {
                    difference.forEach((data) => {
                        coldef.splice(
                            this.colDefs.findIndex((field) => field === data),
                            0,
                            this.colDefs[
                                this.colDefs.findIndex(
                                    (field) => field === data
                                )
                            ]
                        );
                        this.toggleEnabled.set(data.headerName, false);
                    });
                }

                if (coldef.length && this.newColDef !== coldef) {
                    coldef.forEach((data, index) => {
                        const tempFixed =
                            this.columnOrder && this.columnOrder.length
                                ? this.columnOrder.filter((obj) => {
                                      return obj.columnName === data.headerName;
                                  })[0]
                                    ? this.columnOrder.filter((obj) => {
                                          return (
                                              obj.columnName === data.headerName
                                          );
                                      })[0]['fixed']
                                    : false
                                : false;

                        input.push({
                            columnName: data.headerName,
                            order: index,
                            enabled: difference
                                ? !difference.includes(data)
                                    ? true
                                    : false
                                : true,
                            fixed: tempFixed,
                        });
                    });
                }
                this.setColumnNames(coldef, this.disabledColumns);
                this.saveOrder(input);
                this.newColDef = coldef;
            }
        }
    }

    setColumnNames(columns, disabledColumns) {
        let toggleSet = false;
        if (!this.toggleEnabled.size) {
            toggleSet = true;
        }
        this.columnName = [];
        this.widgetRef.changeDetectorRef.detectChanges();
        for (const column of columns) {
            if (
                column['headerName'] &&
                column['headerName'] !== 'Action' &&
                column['headerName'] !== 'More Info'
            ) {
                this.columnName.push(column['headerName']);
                if (toggleSet) {
                    this.toggleEnabled.set(column['headerName'], true);
                }
            }
        }

        if (disabledColumns && disabledColumns.length) {
            disabledColumns.forEach((data) => {
                const disabledColumnIndex = this.columnOrder.findIndex(
                    (field) => field.columnName === data
                );

                if (disabledColumnIndex >= 0) {
                    this.columnName.splice(disabledColumnIndex, 0, data);
                }
            });
        }
        this.widgetRef.changeDetectorRef.detectChanges();
    }

    saveOrder(input?, stopRefreshWidget?: boolean) {
        let refresh = false;
        if (!input) {
            input = this.prepareInput();
            refresh = true;
        }

        const apiArgs: IHitApi = {
            url: ApiUrls.COLUMN_ORDER.replace(
                '{widgetId}',
                this.widgetRef.widgetData.widgetId
            ),
            intactUrl: ApiUrls.COLUMN_ORDER,
            input: input,
            requestType: RequestType.POST,
            uniqueIdentity: Symbol(),
            function: (response) => {
                if (stopRefreshWidget) return;
                if (refresh) {
                    this.widgetRef.refreshWidget();
                }
                this.notificationsService.showSnackBar(
                    'Column ordering has sucessfully saved'
                );
            },
            errorFunction: (error) => {
                this.notificationsService.showSnackBar(
                    Helper.extractErrorMessage(error)
                );
            },
            config: {
                authorization: AuthorizationType.BEARER_TOKEN,
            },
        };
        new HitApi(
            apiArgs,
            this.widgetHttpService,
            this.widgetHttpService.ngZone
        ).hitApi();
    }

    prepareInput() {
        const input = [];
        const i = 0;

        for (const data of this.columnName) {
            const tempFixed =
                this.columnOrder && this.columnOrder.length
                    ? this.columnOrder.filter((orderInfo) => {
                          return orderInfo.columnName === data;
                      })[0]
                        ? this.columnOrder.filter((obj) => {
                              return obj.columnName === data;
                          })[0]['fixed']
                        : false
                    : false;

            input.push({
                columnName: data,
                index: i,
                enabled:
                    this.toggleEnabled.get(data) !== undefined
                        ? this.toggleEnabled.get(data)
                        : true,
                fixed: tempFixed,
            });
        }
        return input;
    }

    resetOrder() {
        const apiArgs: IHitApi = {
            url: ApiUrls.COLUMN_ORDER.replace(
                '{widgetId}',
                this.widgetRef.widgetData.widgetId
            ),
            intactUrl: ApiUrls.COLUMN_ORDER,
            input: {},
            requestType: RequestType.DELETE,
            uniqueIdentity: Symbol(),
            function: (response) => {
                this.widgetRef.refreshWidget();
                this.notificationsService.showSnackBar(
                    'Column ordering  has been reset successfully'
                );
            },
            errorFunction: (error) => {
                this.notificationsService.showSnackBar(
                    Helper.extractErrorMessage(error)
                );
            },
            config: {
                authorization: AuthorizationType.BEARER_TOKEN,
            },
        };

        new HitApi(
            apiArgs,
            this.widgetHttpService,
            this.widgetHttpService.ngZone
        ).hitApi();
    }

    //Mapping coldef according to column order
    mapColumnOrder() {
        if (this.columnOrder && this.columnOrder.length) {
            this.disabledColumns = [];
            this.showGrid = false;
            this.widgetRef.changeDetectorRef.detectChanges();

            const newColOrder = [];
            this.columnOrder.forEach((data) => {
                const column = this.colDefs.find(
                    (field) => field.headerName === data.columnName
                );
                if (column && data.enabled) {
                    newColOrder.push(column);
                } else if (data !== undefined && !data.enabled) {
                    this.disabledColumns.push(data.columnName);
                }
            });
            const difference = this.colDefs.filter(
                (column) =>
                    !newColOrder.includes(column) &&
                    !this.disabledColumns.includes(column.headerName)
            );
            newColOrder.push(...difference);

            this.tableInput.fixedColumnPosition.map((column) => {
                const index = newColOrder.findIndex(
                    (field) => field.field === column.columnKey
                );
                Helper.moveItemInArray(
                    newColOrder,
                    index,
                    column.index === 'lastIndex'
                        ? newColOrder.length - 1
                        : column.index
                );
            });

            this.colDefs = [...newColOrder];
            this.showGrid = true;
            this.widgetRef.changeDetectorRef.detectChanges();
        }
    }
    handlePagination() {
        if (this.tableInput && this.tableInput.pagination) {
            this.pagination = true;
            this.paginationPageSize =
                this.paginationSelectedValue =
                this.paginationDropdownInfo.selectedValue =
                    this.tableInput.pagination.defaultPageSize;
            this.paginationDropdownInfo.listData =
                this.tableInput.pagination.pageSizeOptions;
        }
    }

    onPageSizeChanged(pageSizeValue) {
        this.paginationSelectedValue = pageSizeValue;
        this.agGrid.api.paginationSetPageSize(pageSizeValue);
    }

    goToPage(pageFlow: PageFlow, pageNumber?, inputField?: HTMLInputElement) {
        if (pageFlow === PageFlow.NEXT) {
            this.agGrid.api.paginationGoToNextPage();
        } else if (pageFlow === PageFlow.PREVIOUS) {
            this.agGrid.api.paginationGoToPreviousPage();
        } else if (pageFlow === PageFlow.JUMP) {
            this.agGrid.api.paginationGoToPage(pageNumber - 1);
            if (inputField) {
                inputField.value =
                    +this.agGrid.api.paginationGetCurrentPage() + '';
            }
        }
    }
    isFullWidthCell(rowNode) {
        return rowNode.data.isFullWidthCell;
    }

    public isDropDownVisible:boolean = false;
    public paginationSelectedOptionsList:number[] = [20,25,30,35,40]
    handleToggleDropdown(){
        this.isDropDownVisible = !this.isDropDownVisible;
    }
    handleSelectOption(selectedOption:number){
        this.isDropDownVisible = false;
        if(this.selectedOption === selectedOption){
            return;
        }
        // if start item is greater than than total elements
        else if((((this.pageNumber-1)*(selectedOption))+1)>this.paginationValues.totalRecords){
            this.notificationsService.showSnackBar('Invalid option Selected',true);
            return;
        }
        this.selectedOption = selectedOption;
        this.navigatePage()
    }
}

enum PageFlow {
    NEXT = 'NEXT',
    PREVIOUS = 'PREVIOUS',
    JUMP = 'JUMP',
}
