<div class="controls tw-pb-2" *ngIf="!(widgetRef.loadingData | async)">
    <ng-container *ngIf="columnName && columnName.length">
        <div class="tw-pr-2">
            <app-table-column-order
                [fixedColumns]="fixedColumns"
                [prefixData]="dragIcon"
                [columnName]="columnName"
                [toggleEnabled]="toggleEnabled"
                [iconData]="colOrderIcon"
                [disableDragColumns]="tableInput.disableDragColumns"
                [onSave]="saveOrder.bind(this)"
                [onReset]="resetOrder.bind(this)"
            ></app-table-column-order>
        </div>
    </ng-container>
    <div class="overview" *ngIf="overviewTemplateStart">
        <ng-container *ngTemplateOutlet="overviewTemplateStart"></ng-container>
    </div>
    <div
        class="tw-flex tw-mr-auto tw-flex-wrap"
        *ngIf="!(widgetRef.loadingData | async) && tableInput.buttons"
    >
        <app-multi-button-generator
            [buttonGenInputs]="tableInput.buttons"
        ></app-multi-button-generator>
    </div>
    <div
        class="overview support-overview"
        *ngIf="overviewTemplateEnd"
        style="margin-left: auto"
        [style.marginRight]="showSearchBox ? '' : 'unset'"
    >
        <ng-container *ngTemplateOutlet="overviewTemplateEnd"></ng-container>
    </div>
    <div
        class="table-search"
        [style.marginLeft]="tableInput.buttons ? 'unset' : 'auto'"
        *ngIf="(rowData && rowData.length && showSearchBox)||(showPagination && searchedValue)"
    >
        <app-table-search
            [searchFunction]="showPagination? onPaginationSearch.bind(this):onQuickFilterChanged.bind(this)"
            [isPaginationenabled]="showPagination"
            [widgetRef]="widgetRef"
        ></app-table-search>
    </div>
</div>
<div
    class="widget-data-container"
    [class.tw-h-fit]="tableInput.tableAutoHeight || tableAutoHeight"
    [id]="widgetRef.widgetTableId"
    *ngIf="
        !(widgetRef.loadingData | async) &&
        rowData &&
        rowData.length &&
        widgetRef.visibleSections.value.has(ViewType.TABLE) &&
        showGrid
    "
>
    <div
        [ngClass]="
            widgetRef.widgetLinkingData
                ? 'table-container-compare-view'
                : 'table-container'
        "
        appResizeObserver
        (resize)="handleResize()"
    >
        <ag-grid-angular
            #agGrid
            [id]="tableId"
            class="ag-theme-balham material-icons"
            [rowData]="rowData"
            [columnDefs]="colDefs"
            [animateRows]="true"
            [rowHeight]="tableInput.rowHeight ? tableInput.rowHeight : '37'"
            [rowSelection]="
                tableInput.rowSelection ? tableInput.rowSelection : 'multiple'
            "
            [suppressFieldDotNotation]="true"
            (dragStopped)="colDrag($event)"
            [suppressMovableColumns]="loading"
            [rowClassRules]="widgetRef.tableRowClassRules"
            [defaultColDef]="defaultColDef"
            [frameworkComponents]="frameworkComponents"
            [isRowSelectable]="
                tableInput.isRowSelectable
                    ? tableInput.isRowSelectable
                    : isRowSelectable
            "
            (gridReady)="onGridReady($event)"
            [suppressDragLeaveHidesColumns]="true"
            [icons]="agGridIcons"
            (cellValueChanged)="onCellValueChanged($event)"
            (selectionChanged)="onSelectionChanged($event)"
            [class.auto-height]="tableInput.tableAutoHeight || tableAutoHeight"
            [pagination]="pagination"
            [paginationPageSize]="paginationPageSize"
            [headerHeight]="
                tableInput.headerHeight ? tableInput.headerHeight : null
            "
            [isFullWidthCell]="isFullWidthCell"
            [fullWidthCellRenderer]="
                widgetRef && widgetRef.fullWidthCellRenderer
                    ? widgetRef.fullWidthCellRenderer
                    : null
            "
            [suppressColumnVirtualisation]="true"
        >
        </ag-grid-angular>
        <div
            class="partial-data-loader"
            *ngIf="widgetRef.loadingPartialData | async"
        >
            Loading ......
        </div>
    </div>
</div>

<div class="overview" *ngIf="overviewTemplateBottom" style="margin: auto">
    <ng-container *ngTemplateOutlet="overviewTemplateBottom"></ng-container>
</div>

<div class="pagination-panel" *ngIf="!(widgetRef.loadingData | async)">
    <div
        class="page-size-dropdown"
        *ngIf="pagination && paginationDropdownInfo.listData"
    >
        <span> Rows Per Page : </span>
        <app-single-select-dropdown
            [filterInfo]="paginationDropdownInfo"
            [selectedValue]="paginationSelectedValue"
            [clearable]="false"
            (change)="onPageSizeChanged($event)"
        ></app-single-select-dropdown>
    </div>

    <div class="pages" *ngIf="pagination">
        <button
            [style.visibility]="
                agGrid && agGrid.api.paginationGetCurrentPage() === 0
                    ? 'hidden'
                    : 'visible'
            "
            class="clickable back-page"
            (click)="goToPage(pageFlow.PREVIOUS)"
        >
            <i class="fas fa-chevron-left"></i>
        </button>
        <div class="current-page">
            <input
                #field
                type="number"
                [value]="agGrid ? agGrid.api.paginationGetCurrentPage() + 1 : 1"
                (keyup.enter)="
                    goToPage(pageFlow.JUMP, $event.target.value, $event.target)
                "
                [min]="1"
                [max]="agGrid ? agGrid.api.paginationGetTotalPages() : 1"
            />
            <div class="separator">/</div>
            <span class="totalPages">{{
                agGrid ? agGrid.api.paginationGetTotalPages() : 1
            }}</span>
        </div>
        <button
            [style.visibility]="
                agGrid &&
                agGrid.api.paginationGetCurrentPage() + 1 >=
                    agGrid.api.paginationGetTotalPages()
                    ? 'hidden'
                    : 'visible'
            "
            class="clickable next-page"
            (click)="goToPage(pageFlow.NEXT)"
        >
            <i class="fas fa-chevron-right"></i>
        </button>
    </div>
</div>
<div class="pagination-container" *ngIf="showPagination && rowData && rowData.length && !(widgetRef.loadingData | async) && paginationValues.totalRecords>=paginationSelectedOptionsList[0]">
    <div class="first-child">
        <div class="page-size">
            <p>Page Size:</p>
            <div class="select-dropdown" id="pagesize">
                <div class="dropdown" (click)="handleToggleDropdown()">
                    <span class="selected-option">{{selectedOption}}</span>
                    <img src="../../../../assets/new-icons/dropdown-icon.svg" alt="dropdown icon" [style.transform]="!isDropDownVisible?'rotate(0deg)':'rotate(180deg)'">
                </div>
                <div class="options"  [style.display]="isDropDownVisible ? 'flex' : 'none'">
                    <ng-container *ngFor = "let item of paginationSelectedOptionsList; let i = index" >
                        <div class="option"(click)="handleSelectOption(item)" *ngIf="item<=paginationValues.totalRecords || (i !== 0 && paginationSelectedOptionsList[i - 1]<paginationValues.totalRecords)">
                            <span class="option-text" [ngClass]="item===selectedOption?'selected':''">{{item}}</span>
                        </div>
                    </ng-container>
                </div>
            </div>
        </div>
        <div class="total-pages">
            <p>Showing <span>{{paginationValues.startItem}}</span> to <span>{{paginationValues.endItem}}</span> out of <span>{{paginationValues.totalRecords}}</span></p>
        </div>
    </div>
    <div class="second-child">
        <div class="back-buttons">
            <button (click)="navigatePage('backtofirstpage')">
                <div class="prev first {{pageNumber===1?'disable':''}}"></div>
            </button>
            <button (click)="navigatePage('back')">
                <div class="prev {{pageNumber===1?'disable':''}}"></div>
            </button>
        </div>

        <div class="page-num-input">
            <p>page</p>
            <input type="number" value={{pageNumber}} [(ngModel)]="pageNumber" [max]="paginationValues.totalPages"
                (input)="onPageChange()" (keydown)="onPageChange($event)">
            <p>of <span>{{paginationValues.totalPages}}</span></p>
        </div>
        <div class="forward-buttons">
            <button (click)="navigatePage('next')">
                <div class="next {{pageNumber===paginationValues.totalPages?'disable':''}}"></div>
            </button>
            <button (click)="navigatePage('gotolastpage')">
                <div class=" next last  {{pageNumber===paginationValues.totalPages?'disable':''}}"></div>
            </button>
        </div>
    </div>
</div>
